import { ProductModel, Product, ProductDeletionTranslatedOptions } from '../../../../types/products';

export enum ManageProductsActionsTypes {
  SELECT_ROW = '@manageProducts/SELECT_ROW',
  DELETE = '@manageProducts/DELETE',
  DELETE_SUCCESS = '@manageProducts/DELETE_SUCCESS',
  DELETE_ERROR = '@manageProducts/DELETE_ERROR',
  UPDATE_INVENTORY = '@manageProducts/UPDATE_INVENTORY',
  UPDATE_INVENTORY_SUCCESS = '@manageProducts/UPDATE_INVENTORY_SUCCESS',
  UPDATE_INVENTORY_ERROR = '@manageProducts/UPDATE_INVENTORY_ERROR',
  GET_ALL = '@manageProducts/GET_ALL',
  GET_ALL_SUCCESS = '@manageProducts/GET_ALL_SUCCESS',
  GET_ALL_ERROR = '@manageProducts/GET_ALL_ERROR',
  CLEAR_FEEDBACK_MESSAGE = '@manageProducts/CLEAR_FEEDBACK_MESSAGE',
  DELETE_REASON = '@manageProducts/DELETE_REASON',
}

export interface SelectRowAction {
  type: typeof ManageProductsActionsTypes.SELECT_ROW;
  payload: Partial<ProductModel>;
}

export interface DeleteAction {
  type: typeof ManageProductsActionsTypes.DELETE;
  payload: ProductModel;
}

export interface DeleteSuccessAction {
  type: typeof ManageProductsActionsTypes.DELETE_SUCCESS;
  payload: {
    products: Product[];
    rows: ProductModel[];
    snackbar: any;
  };
}

export interface DeleteErrorAction {
  type: typeof ManageProductsActionsTypes.DELETE_ERROR;
  payload: any;
}

export interface UpdateAction {
  type: typeof ManageProductsActionsTypes.UPDATE_INVENTORY;
  payload: {
    row: ProductModel;
    value: number;
  };
}

export interface UpdateSuccessAction {
  type: typeof ManageProductsActionsTypes.UPDATE_INVENTORY_SUCCESS;
  payload: {
    rows: ProductModel[];
    products: Product[];
    snackbar: any;
  };
}

export interface UpdateErrorAction {
  type: typeof ManageProductsActionsTypes.UPDATE_INVENTORY_ERROR;
  payload: any;
}

export interface GetAllAction {
  type: typeof ManageProductsActionsTypes.GET_ALL;
  payload: {
    useLoading?: boolean;
  };
}

export interface GetAllSuccessAction {
  type: typeof ManageProductsActionsTypes.GET_ALL_SUCCESS;
  payload: {
    products: Product[];
    rows: ProductModel[];
  };
}

export interface GetAllErrorAction {
  type: typeof ManageProductsActionsTypes.GET_ALL_ERROR;
  payload: any;
}

export interface ClearFeedbackMessageAction {
  type: typeof ManageProductsActionsTypes.CLEAR_FEEDBACK_MESSAGE;
  payload: any;
}

export interface DeleteProductAction {
  type: typeof ManageProductsActionsTypes.DELETE_REASON;
  payload: ProductDeletionTranslatedOptions | string;
}

export type ManageProductsActions =
  | SelectRowAction
  | DeleteAction
  | DeleteSuccessAction
  | DeleteErrorAction
  | UpdateAction
  | UpdateSuccessAction
  | UpdateErrorAction
  | GetAllAction
  | GetAllSuccessAction
  | GetAllErrorAction
  | ClearFeedbackMessageAction
  | DeleteProductAction;
