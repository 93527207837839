// Utils
import { FileId, FileUploadPayload } from '../../../../types/files';
import { Metafield } from '../../../../types/products';
import { addProductState } from './reducer';

export enum AddProductActionsTypes {
  ADD_PRODUCT = '@addProducts/ADD_PRODUCT',
  ADD_PRODUCT_SUCCESS = '@addProducts/ADD_PRODUCT_SUCCESS',
  ADD_PRODUCT_ERROR = '@addProducts/ADD_PRODUCT_ERROR',
  SET_FIELDS = '@addProducts/SET_FIELDS',
  UPLOAD_IMAGE = '@addProducts/UPLOAD_IMAGE',
  UPLOAD_IMAGE_SUCCESS = '@addProducts/UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_ERROR = '@addProducts/UPLOAD_IMAGE_ERROR',
  DELETE_IMAGE = '@addProducts/DELETE_IMAGE',
  DELETE_IMAGE_SUCCESS = '@addProducts/DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_ERROR = '@addProducts/DELETE_IMAGE_ERROR',
}

export interface AddProductAction {
  type: typeof AddProductActionsTypes.ADD_PRODUCT;
  payload: {
    title: string;
    description: string;
    image: string;
    pricing: string;
    metafields: Metafield;
  };
}

export interface AddProductSuccessAction {
  type: typeof AddProductActionsTypes.ADD_PRODUCT_SUCCESS;
  payload: any;
}

export interface AddProductErrorAction {
  type: typeof AddProductActionsTypes.ADD_PRODUCT_ERROR;
  payload: any;
}

export interface SetFieldsAction {
  type: typeof AddProductActionsTypes.SET_FIELDS;
  payload: Partial<addProductState>;
}

export interface UploadImageAction {
  type: typeof AddProductActionsTypes.UPLOAD_IMAGE;
  payload: File;
}

export interface UploadImageSuccessAction {
  type: typeof AddProductActionsTypes.UPLOAD_IMAGE_SUCCESS;
  payload: FileUploadPayload;
}

export interface UploadImageErrorAction {
  type: typeof AddProductActionsTypes.UPLOAD_IMAGE_ERROR;
  payload: any;
}

export interface DeleteImageAction {
  type: typeof AddProductActionsTypes.DELETE_IMAGE;
}

export interface DeleteImageSuccessAction {
  type: typeof AddProductActionsTypes.DELETE_IMAGE_SUCCESS;
}

export interface DeleteImageErrorAction {
  type: typeof AddProductActionsTypes.DELETE_IMAGE_ERROR;
  payload: any;
}

export type AddProductActions =
  | AddProductAction
  | AddProductSuccessAction
  | AddProductErrorAction
  | SetFieldsAction
  | UploadImageAction
  | UploadImageSuccessAction
  | UploadImageErrorAction
  | DeleteImageAction
  | DeleteImageSuccessAction
  | DeleteImageErrorAction;
