// Dependencies
import React from 'react';
import { Switch, FormGroup, FormControlLabel, colors } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider, DatePicker, DateValidationError } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import {
  mapSwitchToState,
  switchesLabels,
  switchesStatesObj,
  PRIORITY_ORDERS_TIME,
  PRIORITY_ORDERS_TIME_UNIT,
} from '../../utils/orders-utils';
import { filterOrders, handleSwitches } from '../../pages/orders/states/actions';
import { switchesSelector, ordersSelector } from '../../pages/orders/states/reducer';
import colorConfigs from '../../configs/colorConfigs';

type OrdersFiltersProps = {
  dateFrom: Moment | null;
  dateTo: Moment | null;
  setDateFrom: (val: Moment | null) => void;
  setDateTo: (val: Moment | null) => void;
  setDateError: (val: DateValidationError | null) => void;
  dateError: DateValidationError | undefined;
  ownerId: string;
};

const OrdersFilters = ({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  setDateError,
  dateError,
  ownerId,
}: OrdersFiltersProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const switchesStates = useSelector(switchesSelector);
  const orders = useSelector(ordersSelector);

  const onClear = React.useCallback((action: (val: Moment | null) => void) => {
    setDateError(null);
    action(null);
  }, []);

  return (
    <div
      style={{
        marginBottom: 20,
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <div style={{ display: 'flex', flex: 1 }}>
        <FormGroup sx={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
          {switchesLabels.map((item, index) => (
            <FormControlLabel
              key={item.label}
              control={
                <Switch
                  size="medium"
                  checked={switchesStates[switchesStatesObj[item.label]]}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.checked;
                    dispatch(handleSwitches(mapSwitchToState(item.label, value)));
                    if (item.dispatchEvent === 'filterPriorityOrders') {
                      if (value) {
                        const beginOfDay = moment().startOf('d');
                        dispatch(
                          filterOrders({
                            dateFrom: beginOfDay,
                            dateTo: beginOfDay.clone().add(PRIORITY_ORDERS_TIME, PRIORITY_ORDERS_TIME_UNIT),
                            dateError: null,
                            orders,
                            ownerId,
                          }),
                        );
                      } else {
                        dispatch(
                          filterOrders({
                            dateFrom,
                            dateTo,
                            orders,
                            dateError,
                            ownerId: '',
                          }),
                        );
                      }
                    }
                  }}
                />
              }
              label={t(`orders.configurations.switches.${item.label}.label`)}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '1.3vw',
                  fontWeight: '450',
                  color: colors.grey['800'],
                  paddingRight: '1vw',
                  minWidth: '15vw',
                },
                marginBottom: index === switchesLabels.length - 1 ? '1vh' : '2vh',
                marginLeft: 0,
                borderStyle: 'solid',
                borderColor: colors.grey[700],
                borderRadius: 2,
                borderWidth: '1px',
              }}
            />
          ))}
        </FormGroup>
        {/* <div style={{ flex: 1, flexGrow: 1 }} /> */}
      </div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 0.6 }}>
          <DatePicker
            label={t('orders.configurations.filters.date.from')}
            defaultValue={dateFrom}
            onChange={(val) => {
              setDateFrom(val);
              setDateError(null);
            }}
            sx={{
              // marginRight: '1.2vw',
              marginBottom: '1.5vw',
              width: 'auto',
              '& .MuiInputBase-input': {
                fontSize: '1.3vw',
              },
            }}
            disableFuture
            minDate={moment().subtract(1, 'month')}
            maxDate={dateTo}
            onError={(err) => setDateError(err)}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => onClear(setDateFrom),
              },
              textField: {
                size: 'small',
              },
            }}
          />
          <DatePicker
            label={t('orders.configurations.filters.date.to')}
            sx={{
              width: 'auto',
              '& .MuiInputBase-input': {
                fontSize: '1.3vw',
              },
            }}
            defaultValue={dateTo}
            onChange={(val) => {
              setDateTo(val);
              setDateError(null);
            }}
            disableFuture
            slotProps={{
              field: {
                clearable: true,
                onClear: () => onClear(setDateTo),
              },
              textField: {
                size: 'small',
              },
            }}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default OrdersFilters;
