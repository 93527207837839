import { colors } from '@mui/material';

const colorConfigs = {
  sidebar: {
    // bg: '#233044',
    bg: '#02615D',
    color: '#eeeeee',
    // hoverBg: '#1e293a',
    // activeBg: '#1e253a',
    // activeBg: colors.grey['900'],
    // hoverBg: colors.grey['800'],
    activeBg: colors.blueGrey['900'],
    hoverBg: colors.blueGrey['800'],
  },
  topbar: {
    bg: '#fff',
    // bg: colors.blueGrey['900'],
    color: '#000',
  },
  mainBg: colors.grey['100'],
  warningMsg: '#AF6255',
};

export default colorConfigs;
