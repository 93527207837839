// Dependencies
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { UsersService } from '../../services/users';

// Utils
import { User, UserLocation } from '../../types/user';
import {
  currentLanguageKey,
  getCurrentLanguage,
  getStoredUser,
  ownerUserKey,
  setItem,
  userKey,
} from '../../utils/storage-utils';

const usersService = new UsersService();

export interface UserState {
  name: string;
  isAdmin: boolean;
  userId: string;
  isLoading: boolean;
  ownerUser: UserLocation | null;
  language: string;
  customPrint: boolean;
}

const userInitialState: UserState = {
  name: '',
  isAdmin: false,
  userId: '',
  isLoading: false,
  ownerUser: null,
  language: '',
  customPrint: false,
};

export const userStateSlice = createSlice({
  name: 'userState',
  initialState: userInitialState,
  reducers: {
    loadingUser: (state: UserState) => {
      state.isLoading = !userInitialState.isLoading;
    },
    setUser: (state: UserState, action: PayloadAction<User>) => {
      state.name = action.payload?.username;
      state.isAdmin = action.payload.isAdmin;
      state.userId = action.payload.sub;
      state.isLoading = userInitialState.isLoading;
      state.language = action.payload?.language || '';
      state.customPrint = action.payload?.customPrint || false;
      const storedUser = getStoredUser();

      if (!storedUser || storedUser.name !== state.name || storedUser.customPrint !== state.customPrint) {
        const { name, isAdmin, userId, customPrint } = state;
        setItem(userKey, { name, isAdmin, userId, customPrint });
      }
      if (!state.isAdmin) {
        setItem(ownerUserKey, null);
      }
    },
    resetUser: (state: UserState) => {
      state = userInitialState;
      setItem(userKey, null);
    },
    setOwnerUser: (state: UserState, action: PayloadAction<UserLocation | null>) => {
      state.ownerUser = action.payload;
      setItem(ownerUserKey, action.payload);
    },
  },
  selectors: {
    userSelector: (state: UserState): UserState => state,
    isAdminSelector: (state: UserState): boolean => state.isAdmin,
    userNameSelector: (state: UserState): string => state.name,
    isUserLoadingSelector: (state: UserState): boolean => state.isLoading,
    ownerUserSelector: (state: UserState): UserLocation | null => state.ownerUser,
  },
});

export const { loadingUser, setUser, resetUser, setOwnerUser } = userStateSlice.actions;

export const { userSelector, isAdminSelector, userNameSelector, isUserLoadingSelector, ownerUserSelector } =
  userStateSlice.selectors;

export const fetchUser = async (userId: string, dispatch: Dispatch): Promise<User> => {
  if (!userId) {
    dispatch(loadingUser());
  }
  const { data } = await usersService.get();
  dispatch(setUser(data));
  const language = getCurrentLanguage();
  const isLanguageDifferent = data.language && data.language !== language;

  if (!data.language) {
    await usersService.setLanguage({ language });
  } else if (isLanguageDifferent) {
    setItem(currentLanguageKey, data.language);
  }
  return data;
};

export const findUsers = async (search: string) => {
  const { data } = await usersService.find({ name: search });
  return data;
};

export const getLoggedUser = (selector: any) => {
  const stateUser: UserState = selector(userSelector);
  if (stateUser.name || stateUser.userId) {
    return stateUser;
  }
  return getStoredUser();
};

export default userStateSlice.reducer;
