// Utils
import {
  RequestId,
  PendingRequestModel,
  GetPendingRequestsParams,
  Actions,
  PendingRequests,
} from '../../../types/requests';

export enum RequestsActionsTypes {
  GET_ALL_REQUESTS = '@requests/GET_ALL_REQUESTS',
  GET_ALL_REQUESTS_SUCCESS = '@requests/GET_ALL_REQUESTS_SUCCESS',
  GET_ALL_REQUESTS_ERROR = '@requests/GET_ALL_REQUESTS_ERROR',
  UPDATE = '@requests/UPDATE',
  UPDATE_SUCCESS = '@requests/UPDATE_SUCCESS',
  UPDATE_ERROR = '@requests/UPDATE_ERROR',
  CLEAR_FEEDBACK_MESSAGE = '@requests/CLEAR_FEEDBACK_MESSAGE',
}

export type GetAllRequestsAction = {
  type: typeof RequestsActionsTypes.GET_ALL_REQUESTS;
  payload: GetPendingRequestsParams;
};

export type GetAllRequestsSuccessAction = {
  type: typeof RequestsActionsTypes.GET_ALL_REQUESTS_SUCCESS;
  payload: PendingRequestModel[];
};

export type GetAllRequestsErrorAction = {
  type: typeof RequestsActionsTypes.GET_ALL_REQUESTS_ERROR;
  payload: any;
};

export type UpdateAction = {
  type: typeof RequestsActionsTypes.UPDATE;
  payload: {
    id: RequestId;
    action: Actions;
  };
};

export type UpdateSuccessAction = {
  type: typeof RequestsActionsTypes.UPDATE_SUCCESS;
  payload: {
    data: PendingRequests;
    snackbar: any;
  };
};

export type UpdateErrorAction = {
  type: typeof RequestsActionsTypes.UPDATE_ERROR;
  payload: any;
};

export interface ClearFeedbackMessageAction {
  type: typeof RequestsActionsTypes.CLEAR_FEEDBACK_MESSAGE;
}

export type RequestsActions =
  | GetAllRequestsAction
  | GetAllRequestsSuccessAction
  | GetAllRequestsErrorAction
  | UpdateAction
  | UpdateSuccessAction
  | UpdateErrorAction
  | ClearFeedbackMessageAction;
