// Dependencies
import * as React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField, Button, colors } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import colorConfigs from '../../configs/colorConfigs';
import { OrdersDeclinePathOptions, OrdersDeclineTranslatedOptions } from '../../types/orders';
import { selectCurrentPath } from '../../redux/features/appStateSlice';
import { setOrderDeclinementReason } from '../../pages/orders/states/actions';
import { orderDeclinementReasonSelector } from '../../pages/orders/states/reducer';
import { ProductDeletionTranslatedOptions, ProductDeletionPathOption } from '../../types/products';
import { translateToLng } from '../../i18n';
import { setDeleteProductReason } from '../../pages/products/manage/states/actions';
import { deleteProductReasonSelector } from '../../pages/products/manage/states/reducer';

export type RadioButtonOptions = {
  value: string | number;
  label: ProductDeletionPathOption | OrdersDeclinePathOptions;
  type?: 'input' | 'radio';
};

type RadioButtonListProps = {
  handleClose: () => void;
  buttonLabel: string;
  title?: string;
  onSend: () => any;
  options: Array<RadioButtonOptions>;
};

const RadioButtonsList = ({ handleClose, buttonLabel, title = '', onSend, options = [] }: RadioButtonListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentPath = useSelector(selectCurrentPath);
  const orderDeclinementReason = useSelector(orderDeclinementReasonSelector);
  const productDeleteReason = useSelector(deleteProductReasonSelector);

  const [message, setMessage] = React.useState<string>('');
  const [showInput, setShowInput] = React.useState<boolean>(false);

  const isButtonDisabled = React.useCallback((): boolean => {
    if (currentPath === 'orders.manage') {
      return !orderDeclinementReason;
    }
    if (currentPath === 'products.manage') {
      // return ((showInput && !message) || !productDeleteReason)
      return !productDeleteReason;
    }
    return true;
  }, [message, orderDeclinementReason, showInput, productDeleteReason, currentPath]);

  const handleChange = React.useCallback(
    (opt: RadioButtonOptions) => {
      let reason = translateToLng(opt.label);
      if (currentPath === 'orders.manage') {
        dispatch(setOrderDeclinementReason(reason as OrdersDeclineTranslatedOptions));
      } else if (currentPath === 'products.manage') {
        // if (!!message) reason = message
        if (opt.type !== 'input') {
          dispatch(setDeleteProductReason(reason as ProductDeletionTranslatedOptions));
        }
      }

      if (opt.type === 'input') {
        setShowInput(true);
        dispatch(setDeleteProductReason(''));
      } else {
        setShowInput(false);
        setMessage('');
      }
    },
    [showInput, currentPath, message],
  );

  const onClick = React.useCallback(async () => {
    await onSend();
    handleClose();
    setShowInput(false);
  }, [orderDeclinementReason]);

  return (
    <FormControl key="radio-form-control" sx={{ width: '100%', display: 'flex' }}>
      <FormLabel
        key="radio-form-label"
        id="radio-buttons-group-label"
        sx={{
          color: colors.grey['800'],
          fontWeight: 'bold',
          marginBottom: 2,
        }}
      >
        {title}
      </FormLabel>
      <RadioGroup
        key="radio-radio-group"
        aria-labelledby="radio-buttons-group-label"
        name="radio-buttons-group"
        // onChange={handleChange}
      >
        {options.map((opt, index) => (
          <React.Fragment key={index}>
            <FormControlLabel
              onClick={() => handleChange(opt)}
              key={`form-control-label-${index}`}
              value={opt.value}
              control={<Radio key={`radio-${index}`} size="small" />}
              label={t(opt.label)}
              componentsProps={{
                typography: {
                  sx: {
                    fontSize: '1.2vw',
                  },
                },
              }}
            />
            {showInput && opt.type === 'input' && (
              <TextField
                key={`input-${index}`}
                fullWidth
                label=""
                id="radio-buttons-group-label"
                value={message}
                onChange={(e) => {
                  const message = e.target.value;
                  setMessage(message);
                  dispatch(setDeleteProductReason(message));
                }}
                multiline
              />
            )}
          </React.Fragment>
        ))}
        <Button
          disabled={isButtonDisabled()}
          onClick={onClick}
          sx={{ marginTop: 2, backgroundColor: colorConfigs.sidebar.bg }}
          variant="contained"
        >
          {buttonLabel}
        </Button>
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsList;
