// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';

// Components
import Section from '../../../components/common/AddProductSection';
import { InputBaseComponent } from '../../../components/common/Inputs';
import MetaFieldsList, { MetafieldsButtons } from '../../../components/common/MetafieldsList';
import ImageUploader from '../../../components/common/ImageUploader';

// Utils
import {
  fieldSelector,
  isLoadingSelector,
  metafieldsListSelector,
  metafieldsSelector,
  showProductsMetafieldsStateSelector,
  showVariantsMetafieldsStateSelector,
  snackbarSelector,
} from './states/reducer';
import { MetafieldOptions } from '../../../types/products';
import {
  currencyMask,
  firstLetterTypedAlwaysCapital,
  firstLetterTypedCapital,
  removeCurrencyMask,
} from '../../../utils/string-utils';
import { addProduct, setFields } from './states/actions';
import { RootState } from '../../../redux/store';
import { validateMetafields } from '../../../utils/product-utils';
import { getLoggedUser } from '../../../redux/features/userStateSlice';

const AddProductPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);
  const snackbar = useSelector(snackbarSelector);
  const title = useSelector((state: RootState) => fieldSelector(state, 'title'));
  const description = useSelector((state: RootState) => fieldSelector(state, 'description'));
  const image = useSelector((state: RootState) => fieldSelector(state, 'image'));
  const pricing = useSelector((state: RootState) => fieldSelector(state, 'pricing'));
  const metafields = useSelector(metafieldsSelector);
  const metafieldsList = useSelector(metafieldsListSelector);
  const showAllProductsMetafields = useSelector(showProductsMetafieldsStateSelector);
  const showAllVariantsMetafields = useSelector(showVariantsMetafieldsStateSelector);
  const user = getLoggedUser(useSelector);

  const isDisabled = React.useCallback(() => {
    if (!user || (user.isAdmin && !user.ownerUser?.ownerId)) {
      return true;
    }
    return (
      !title || !description || !image || !parseFloat(pricing?.replace('€', '')) || !validateMetafields(metafields)
    );
  }, [title, description, image, pricing, metafields, isLoading, metafieldsList, user?.ownerUser?.ownerId]);

  const handleCloseSnackbar = () => dispatch(setFields({ snackbar: null }));

  const getButtonLabel = React.useCallback(
    (item: MetafieldOptions): MetafieldsButtons => {
      let productsButtonLabel = t(`products.add.buttons.showAll`);
      let variantsButtonLabel = t(`products.add.buttons.showAll`);

      if (item.type === 'product' && showAllProductsMetafields) {
        productsButtonLabel = t(`products.add.buttons.hide`);
      }

      if (item.type === 'variant' && showAllVariantsMetafields) {
        variantsButtonLabel = t(`products.add.buttons.hide`);
      }

      return {
        product: productsButtonLabel,
        variant: variantsButtonLabel,
      };
    },
    [showAllProductsMetafields, showAllVariantsMetafields],
  );

  const handleButtonClick = React.useCallback(
    (item: MetafieldOptions) => {
      if (item.type === 'product') {
        return dispatch(setFields({ showAllProductsMetafields: !showAllProductsMetafields }));
      }
      if (item.type === 'variant') {
        return dispatch(setFields({ showAllVariantsMetafields: !showAllVariantsMetafields }));
      }
    },
    [showAllProductsMetafields, showAllVariantsMetafields],
  );

  return (
    <Box style={{ width: '100%' }}>
      <Section title={t('products.add.sections.general.title')}>
        <InputBaseComponent
          disabled={isLoading}
          label={t('products.add.sections.general.inputs.first.label')}
          placeholder={t('products.add.sections.general.inputs.first.placeholder')}
          id="product-name-input"
          fullWidth
          style={{ marginBottom: '1.3vw' }}
          value={title}
          onChange={(e) =>
            dispatch(
              setFields({
                title: firstLetterTypedAlwaysCapital(e.target.value),
              }),
            )
          }
        />
        <InputBaseComponent
          disabled={isLoading}
          label={t('products.add.sections.general.inputs.second.label')}
          id="product-description-input"
          fullWidth
          multiline
          minRows={4}
          value={description}
          onChange={(e) =>
            dispatch(
              setFields({
                description: firstLetterTypedCapital(e.target.value),
              }),
            )
          }
        />
      </Section>
      <Section title={t('products.add.sections.media.title')}>
        <ImageUploader />
      </Section>
      <Section title={t('products.add.sections.pricing.title')}>
        <InputBaseComponent
          disabled={isLoading}
          label={t('products.add.sections.pricing.inputs.first.label')}
          placeholder="€ 0.00"
          id="product-item-price-input"
          value={pricing}
          onChange={(e) => dispatch(setFields({ pricing: currencyMask(e.target.value) }))}
        />
      </Section>
      <Section title={t('products.add.sections.metafields.title')}>
        <MetaFieldsList
          getButtonLabel={getButtonLabel}
          handleButtonClick={handleButtonClick}
          disabled={isLoading}
          list={metafieldsList}
        />
      </Section>
      <LoadingButton
        variant="contained"
        disabled={isDisabled()}
        fullWidth
        loading={isLoading}
        onClick={() =>
          dispatch(
            addProduct({
              title,
              description,
              image,
              metafields,
              pricing: removeCurrencyMask(pricing),
            }),
          )
        }
      >
        {t('products.add.buttons.save')}
      </LoadingButton>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
};

export default AddProductPage;
