// Dependencies
import { ImageListType } from 'react-images-uploading';

// Types
import { Metafield, MetafieldOptions } from '../../../../types/products';
import { AddProductActions, AddProductActionsTypes } from './types';

// Utils
import { RootState } from '../../../../redux/store';
import { getMetafields } from '../../../../utils/product-utils';
export interface addProductState {
  title: string;
  description: string;
  image: string;
  imageList: ImageListType;
  pricing: string;
  metafields: Metafield;
  metafieldsList: MetafieldOptions[];
  snackbar: any;
  isLoading: boolean;
  showAllProductsMetafields: boolean;
  showAllVariantsMetafields: boolean;
  isImageLoading: boolean;
}

export const addProductInitialState: addProductState = {
  title: '',
  description: '',
  image: '',
  imageList: [],
  pricing: '',
  metafields: {
    exclusiveToRoasterCup: '',
    typeOfProductListing: '',
    netWeight: '',
    roasterCountry: '',
    decafYesNo: '',
    coffeeFlavor: '',
    roastType: '',
    singleOrigin: '',
    beanType: '',
    accessories: '',
    equipment: '',
    organic: '',
    fairtrade: '',
    grind: '',
    netWeightVariant: '',
    priceVariant: '',
    process: '',
  },
  metafieldsList: getMetafields(undefined),
  snackbar: null,
  isLoading: false,
  showAllProductsMetafields: false,
  showAllVariantsMetafields: false,
  isImageLoading: false,
};

export const fieldSelector = (state: RootState, field: 'title' | 'description' | 'image' | 'pricing'): string =>
  state.addProduct[field];

export const snackbarSelector = (state: RootState): any => state.addProduct.snackbar;

export const isLoadingSelector = (state: RootState): boolean => state.addProduct.isLoading;

export const metafieldsSelector = (state: RootState): Metafield => state.addProduct.metafields;

export const metafieldsListSelector = (state: RootState): MetafieldOptions[] => state.addProduct.metafieldsList;

export const showProductsMetafieldsStateSelector = (state: RootState): boolean =>
  state.addProduct.showAllProductsMetafields;

export const showVariantsMetafieldsStateSelector = (state: RootState): boolean =>
  state.addProduct.showAllVariantsMetafields;

export const isImageLoadingSelector = (state: RootState): boolean => state.addProduct.isImageLoading;

export const imageListSelector = (state: RootState): ImageListType => state.addProduct.imageList;

export const addProductReducer = (
  state: addProductState = addProductInitialState,
  action: AddProductActions,
): addProductState => {
  switch (action.type) {
    case AddProductActionsTypes.ADD_PRODUCT:
      return {
        ...state,
        isLoading: !addProductInitialState.isLoading,
      };
    case AddProductActionsTypes.ADD_PRODUCT_SUCCESS:
      return {
        ...addProductInitialState,
        snackbar: action.payload,
      };
    case AddProductActionsTypes.ADD_PRODUCT_ERROR:
      return {
        ...state,
        snackbar: action.payload,
        isLoading: addProductInitialState.isLoading,
      };
    case AddProductActionsTypes.SET_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case AddProductActionsTypes.UPLOAD_IMAGE:
    case AddProductActionsTypes.DELETE_IMAGE:
      return {
        ...state,
        isImageLoading: !addProductInitialState.isImageLoading,
      };
    case AddProductActionsTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isImageLoading: addProductInitialState.isImageLoading,
        image: action.payload.id,
      };
    case AddProductActionsTypes.UPLOAD_IMAGE_ERROR:
    case AddProductActionsTypes.DELETE_IMAGE_ERROR:
      return {
        ...state,
        isImageLoading: addProductInitialState.isImageLoading,
        snackbar: action.payload,
      };
    case AddProductActionsTypes.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        isImageLoading: addProductInitialState.isImageLoading,
        image: addProductInitialState.image,
        imageList: addProductInitialState.imageList,
      };
    default:
      return state;
  }
};
