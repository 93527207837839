import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLoggedUser } from '../../redux/features/userStateSlice';
import { ContactsService } from '../../services/contacts';
import { ContactsPayload } from '../../types/contacts';

interface ContactsDropdownProps {
  onSelect: (contact: ContactsPayload) => void;
}

export default function ContactsDropdown({ onSelect }: ContactsDropdownProps) {
  const user = getLoggedUser(useSelector);
  const contactService = new ContactsService();
  const { t } = useTranslation();
  const { data, isLoading, isError } = useQuery({
    queryKey: ['contacts', user.userId],
    queryFn: () => contactService.get({ userId: user.userId }),
  });

  // Keep track of the currently selected contact
  const [selectedValue, setSelectedValue] = React.useState<ContactsPayload | null>(null);

  if (isLoading) return <div>Loading contacts...</div>;
  if (isError || !data) return <div>Error fetching contacts...</div>;

  const contacts: ContactsPayload[] = data.data;

  return (
    <Autocomplete
      options={contacts}
      value={selectedValue}
      onChange={(event, newValue) => {
        setSelectedValue(newValue);
        if (newValue) {
          onSelect(newValue);
        }
      }}
      getOptionLabel={(option) => option.name || ''}
      renderInput={(params) => <TextField {...params} label={t('utilities.createLabel.select-contact')} />}
      sx={{ width: 240 }}
    />
  );
}
