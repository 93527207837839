// Dependencies
import { GridCellParams, GridRowParams } from '@mui/x-data-grid';

// Types
import { RadioButtonOptions } from '../components/common/RadioButtonsList';
import {
  Metafield,
  MetafieldKeysEnum,
  MetafieldOptions,
  Metafields,
  MetafieldsOptionsValuesEnum,
  MetafieldsTitles,
  MetafieldsValueType,
  MetafieldsValueTypeEnum,
  Product,
  ProductModelPrice,
  ProductModel,
  VariantId,
  MetafieldComponentTypes,
} from '../types/products';

// Utils
import { translate, translateToLng } from '../i18n';
import { addProductState } from '../pages/products/add/states/reducer';
import { API_URL } from './constants-utils';
import { removeCurrencyMask } from './string-utils';

export const mockedRows: ProductModel[] = [
  {
    id: 1,
    product: 'Colombie Kachalu',
    inventory: 50,
    category: 'coffee',
    price: { main: '10.50' },
    preDiscountPrice: '11.50',
    img: 'https://cdn.shopify.com/s/files/1/0777/2376/3018/files/1936_Fotor_40x40@3x.png?v=1700252050',
    pendingDeletion: false,
    variantId: 1,
  },
  {
    id: 2,
    product: 'Colombie Tumbaga',
    inventory: 75,
    category: 'coffee',
    price: { main: '9.90' },
    preDiscountPrice: '13.50',
    img: 'https://cdn.shopify.com/s/files/1/0777/2376/3018/files/PastedGraphic5_Fotor_40x40@3x.png?v=1700255426',
    pendingDeletion: false,
    variantId: 2,
  },
  {
    id: 3,
    product: 'Sweet Dreams',
    inventory: 5,
    category: 'coffee',
    price: { main: '8.00' },
    preDiscountPrice: '9.50',
    img: 'https://cdn.shopify.com/s/files/1/0777/2376/3018/files/PastedGraphic2_Fotor_40x40@3x.png?v=1700252316',
    pendingDeletion: false,
    variantId: 3,
  },
];

export const optionsList: Array<RadioButtonOptions> = [
  { value: 0, label: `products.manage.modal.options.0`, type: 'radio' },
  { value: 1, label: `products.manage.modal.options.1`, type: 'radio' },
  { value: 2, label: `products.manage.modal.options.2`, type: 'input' },
];

export const metafieldsList: Array<MetafieldOptions> = [
  {
    title: 'typeOfProductListing',
    options: Array(5).fill(''),
    type: 'product',
    componentType: 'select',
    index: true,
    alwaysShow: true,
  },
  {
    title: 'accessories',
    options: Array(6).fill(''),
    type: 'product',
    componentType: 'select',
  },
  {
    title: 'equipment',
    options: Array(3).fill(''),
    type: 'product',
    componentType: 'select',
  },
  {
    title: 'organic',
    options: Array(2).fill(''),
    type: 'product',
    componentType: 'select',
    isBoolean: true,
  },
  {
    title: 'fairtrade',
    options: Array(2).fill(''),
    type: 'product',
    componentType: 'select',
    isBoolean: true,
  },
  {
    title: 'exclusiveToRoasterCup',
    options: Array(2).fill(''),
    type: 'product',
    componentType: 'select',
  },
  {
    title: 'netWeight',
    type: 'product',
    componentType: 'input',
    isMandatory: true,
    showAfterTypeOfProduct: true,
  },
  {
    title: 'roasterCountry',
    options: Array(4).fill(''),
    type: 'product',
    componentType: 'select',
    isMandatory: true,
    showAfterTypeOfProduct: true,
  },
  {
    title: 'decafYesNo',
    options: Array(2).fill(''),
    type: 'product',
    componentType: 'select',
  },
  {
    title: 'coffeeFlavor',
    options: Array(8).fill(''),
    type: 'product',
    componentType: 'select-multiple',
  },
  {
    title: 'roastType',
    options: Array(3).fill(''),
    type: 'product',
    componentType: 'select',
  },
  {
    title: 'singleOrigin',
    options: Array(13).fill(''),
    type: 'product',
    componentType: 'select',
  },
  {
    title: 'beanType',
    options: Array(4).fill(''),
    type: 'product',
    componentType: 'select',
  },
  {
    title: 'process',
    options: Array(4).fill(''),
    type: 'product',
    componentType: 'select',
  },
  {
    title: 'grind',
    options: Array(4).fill(''),
    type: 'variant',
    componentType: 'select-multiple',
    index: true,
  },
  {
    title: 'netWeightVariant',
    type: 'variant',
    componentType: 'input',
    alwaysShow: true,
    isMandatory: false,
  },
  {
    title: 'priceVariant',
    type: 'variant',
    componentType: 'price-input',
    isMandatory: false,
  },
];

export const mapTypeOfProductListing = (value: number | undefined): string =>
  translateToLng(`products.add.sections.metafields.fields.typeOfProductListing.options.${value}`);

export const getMetafields = (typeOfProductListingOptionValue: number | undefined): Array<MetafieldOptions> => {
  const option = mapTypeOfProductListing(typeOfProductListingOptionValue);
  let data: Array<MetafieldOptions> = [];
  const list: Array<MetafieldOptions> = JSON.parse(JSON.stringify(metafieldsList));

  switch (option) {
    case 'tea':
      data = list.filter(
        (field) =>
          field.alwaysShow ||
          field.showAfterTypeOfProduct ||
          field.title === 'organic' ||
          field.title === 'fairtrade' ||
          field.title === 'exclusiveToRoasterCup' ||
          field.title === 'decafYesNo',
      );
      break;
    case 'whole bean coffee':
      data = list.filter(
        (field) =>
          field.alwaysShow || (field.title !== 'accessories' && field.title !== 'equipment' && field.title !== 'grind'),
      );
      break;
    case 'ground coffee':
      data = list.filter(
        (field) =>
          field.alwaysShow ||
          (field.title !== 'accessories' && field.title !== 'equipment' && field.title !== 'priceVariant'),
      );
      break;
    case 'accessories':
      data = list.filter((field) => field.alwaysShow || field.showAfterTypeOfProduct || field.title === 'accessories');
      break;
    case 'equipment':
      data = list.filter((field) => field.alwaysShow || field.showAfterTypeOfProduct || field.title === 'equipment');
      break;
    default:
      data = list.filter((field) => field.alwaysShow);
  }
  if (option !== 'ground coffee') {
    data = data.map((field) => {
      if (field.title === 'netWeightVariant') {
        field.index = true;
      }
      return field;
    });
  }
  return data;
};

export const mapRowModel = (product: Product): ProductModel => {
  let requestedPrice: string | undefined = undefined;
  let pendingDeletion: boolean = false;
  if (product?.pendingApprovals) {
    product.pendingApprovals.forEach((item) => {
      if (item.type === 'PRODUCT_DELETION') {
        pendingDeletion = true;
      } else if (item.type === 'PRODUCT_PRICE_CHANGE') {
        requestedPrice = item.info?.price;
      }
    });
  }

  return {
    id: product.id,
    product: product.title,
    inventory: product.variant.inventory_quantity || 0,
    price: {
      main: product.variant.price || '0',
      requested: typeof requestedPrice === 'number' ? parseFloat(requestedPrice).toFixed(2) : requestedPrice,
    },
    preDiscountPrice: product.variant?.compare_at_price || '',
    category: product?.product_type
      ? translate(`products.manage.table.column.category.${product.product_type.toLowerCase()}`)
      : '',
    img: product?.files && product.files[0] ? `${API_URL}/api/v1/files/${product.files[0]}` : '',
    pendingDeletion,
    roaster: product.vendor,
    variantId: product.variant.id,
    type: product.variant?.title || '',
    variant_ids: product.variant_ids,
    shouldUpdateMultiple: product.shouldUpdateMultiple,
  };
};

export const productModelPriceFormatter = (price: ProductModelPrice): ProductModelPrice => {
  const main = `€ ${parseFloat(price?.main).toFixed(2)}`;
  if (price.requested) {
    return {
      main: main
        .split('')
        .map((char) => char + '\u0336')
        .join(''),
      requested: ` € ${parseFloat(price?.requested.toString()).toFixed(2)}`,
    };
  }
  return {
    main,
  };
};

export const isProductPendingDeletionApproval = (products: ProductModel[]) =>
  products.filter((product) => !!product.pendingDeletion);

export const isProductDisabled = (params: GridCellParams | GridRowParams, products: ProductModel[]): boolean => {
  const disabledProducts = isProductPendingDeletionApproval(products);
  return !!disabledProducts.find((product) => product.id === params.id);
};

const getMetafieldValue = (
  metafield: MetafieldsTitles,
  value: number | undefined | string | string[],
): string | number | boolean | string[] => {
  const componentType: MetafieldComponentTypes | undefined = metafieldsList.find(
    (item) => item.title === metafield,
  )?.componentType;
  let option: any = translateToLng(`products.add.sections.metafields.fields.${metafield}.options.${value}`);
  const parsedMetafield = JSON.parse(MetafieldsOptionsValuesEnum[metafield]);

  let formattedOption: any = parsedMetafield.find(
    (opt: string) => opt.toLowerCase().trim() === option.toLowerCase().trim(),
  );

  if (componentType && componentType === 'select-multiple') {
    option = (value as string[]).map(
      (val) => `\"${translateToLng(`products.add.sections.metafields.fields.${metafield}.options.${val}`)}\"`,
    );
    formattedOption = '[' + option + ']';
    return formattedOption;
  }

  const valueTypeParam: MetafieldsValueType = MetafieldsValueTypeEnum[metafield];
  if (valueTypeParam === 'number_integer' || valueTypeParam === 'number_decimal') {
    let stringValue: string = value as string;
    if (stringValue.indexOf('€') >= 0) {
      formattedOption = removeCurrencyMask(stringValue);
    } else {
      formattedOption = parseFloat(stringValue);
    }
  } else if (valueTypeParam === 'boolean') {
    formattedOption = formattedOption?.toString()?.toLowerCase() === 'true' ? true : false;
  } else if (MetafieldsValueTypeEnum[metafield] === 'list.single_line_text_field') {
    formattedOption = '["' + formattedOption + '"]';
  }
  return formattedOption;
};

const mapMetafieldsToAddProductApiInput = (
  metafieldsState: Metafield | undefined,
  metafieldsList: MetafieldOptions[],
) => {
  const data: Metafields[] = [];
  if (metafieldsState) {
    metafieldsList.forEach((item) => {
      if (!item.isMandatory && item.type === 'variant' && !metafieldsState[item.title]) {
        return;
      }
      const metafield: Metafields = {
        key: MetafieldKeysEnum[item.title],
        // @ts-ignore
        value: getMetafieldValue(item.title, metafieldsState[item.title]),
        value_type: MetafieldsValueTypeEnum[item.title],
        namespace: 'custom',
      };
      data.push(metafield);
    });
  }
  return data;
};

export const mapStateToAddProductApiInput = (
  state: Partial<addProductState>,
  metafieldsList: MetafieldOptions[],
): Partial<Product> => ({
  body_html: state.description,
  title: state.title,
  price: state.pricing,
  metafields: mapMetafieldsToAddProductApiInput(state.metafields, metafieldsList),
  files: [state?.image as string],
});

export const validadeNumericMetafield = (field: MetafieldOptions, value: string): boolean => {
  if (
    MetafieldsValueTypeEnum[field.title] === 'number_integer' ||
    MetafieldsValueTypeEnum[field.title] === 'number_decimal'
  ) {
    const values = JSON.parse(MetafieldsOptionsValuesEnum[field.title]);
    if (value.indexOf('€') >= 0) {
      value = removeCurrencyMask(value);
    }
    if (parseFloat(value) < Math.min(...values) || parseFloat(value) > Math.max(...values)) {
      return true;
    }
  }
  return false;
};

export const validateMetafields = (metafieldsState: Metafield): boolean => {
  let isValid = true;

  if (!metafieldsState.typeOfProductListing && metafieldsState.typeOfProductListing !== 0) {
    return !isValid;
  }

  const metafields = getMetafields(metafieldsState.typeOfProductListing as number);

  for (let i = 0; i < metafields.length; i++) {
    const field = metafields[i];

    const value: string = metafieldsState[field.title].toString();

    if (field.isMandatory && !value) {
      isValid = false;
      break;
    } else if (field.componentType === 'input' && field.type !== 'variant') {
      // isValid = true
      if (validadeNumericMetafield(field, value)) {
        isValid = false;
        break;
      }
    } else if (field.type === 'variant' && field.componentType !== 'select') {
      isValid = true;
      if (value && validadeNumericMetafield(field, value)) {
        isValid = false;
        break;
      }
    } else if (value === '' || value === null || value === undefined) {
      isValid = false;
      break;
    }
  }
  return isValid;
};

export const findProductAndPosition = (
  products: Product[],
  id: number | string,
  variantId: VariantId,
  error: any,
): { product: Product; positions: number[] } => {
  let positions: number[] = [];
  let product;
  products.map((p, index) => {
    if (p.id == id) {
      if (p?.shouldUpdateMultiple) {
        positions.push(index);
      }
      if (p.variant.id === variantId) {
        product = p;
        if (!p?.shouldUpdateMultiple) {
          positions.push(index);
        }
      }
    }
    return p;
  });

  if (!product || !positions.length) throw error;

  return {
    positions,
    product,
  };
};
