// Dependencies
import * as React from 'react';
import { Card, CardContent, Typography, colors, CardActions, Button, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { PendingRequestModel } from '../../types/requests';
import { requestIdUpdatingSelector } from '../../pages/requests/states/reducer';
import { update } from '../../pages/requests/states/actions';
import { CentralCircularLoader } from './CircularLoader';
import { selectCurrentPath } from '../../redux/features/appStateSlice';

const StyledCard = styled(Card)(({ theme }) => ({
  ...theme.typography.body2,
  marginBottom: theme.spacing(0.75),
  padding: 5,
  display: 'grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: '70% 30%',
  alignItems: 'center',
  width: '100%',
}));

export type RequestCardProps = {
  item: PendingRequestModel;
};

const BoldText = styled('strong')(() => ({
  color: colors.grey[800],
  fontSize: '1.5vw',
}));

const Text = styled(Typography)(() => ({
  fontSize: '1.5vw',
  color: colors.grey[900],
}));

export const RequestsCard = ({ item }: RequestCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentPath = useSelector(selectCurrentPath);
  const requestUpdating = useSelector(requestIdUpdatingSelector);
  const isRequestUpdating = item.id === requestUpdating;

  const RenderData = () =>
    React.useMemo(() => {
      if (currentPath === 'requests.delete-product') {
        return (
          <React.Fragment>
            <Text>
              <BoldText>{t('requests.cards.product')}:</BoldText> {item.object.product}
            </Text>
            <Text>
              <BoldText>{t('requests.cards.roaster')}:</BoldText> {item.object.roaster}
            </Text>
            <Text>
              <BoldText>{t('requests.cards.reason')}:</BoldText> {item.info?.reason}
            </Text>
          </React.Fragment>
        );
      } else if (currentPath === 'requests.change-price') {
        return (
          <React.Fragment>
            <Text>
              <BoldText>{t('requests.cards.product')}:</BoldText> {item.object.product}
            </Text>
            <Text>
              <BoldText>{t('requests.cards.roaster')}:</BoldText> {item.object.roaster}
            </Text>
            <Text>
              <BoldText>{t('requests.cards.currentPrice')}:</BoldText> €{item.object?.price.main}
            </Text>
            <Text sx={{ color: colors.green[700] }}>
              <BoldText style={{ color: colors.green[800] }}>{t('requests.cards.requestedPrice')}:</BoldText> €
              {item.info?.price}
            </Text>
          </React.Fragment>
        );
      }
      return <></>;
    }, [currentPath]);

  return (
    <StyledCard>
      {isRequestUpdating && <CentralCircularLoader />}
      <CardContent sx={{ paddingBottom: '8px' }}>{RenderData()}</CardContent>
      <CardActions sx={{ justifyContent: 'center', padding: 0 }}>
        <Button
          disabled={isRequestUpdating}
          sx={{ flex: 1 }}
          onClick={() => dispatch(update({ id: item.id, action: 'APPROVE' }))}
        >
          {t('requests.cards.buttons.approve.label')}
        </Button>
        <Button
          disabled={isRequestUpdating}
          sx={{ flex: 1, color: colors.red['900'] }}
          onClick={() => dispatch(update({ id: item.id, action: 'REJECT' }))}
        >
          {t('requests.cards.buttons.reject.label')}
        </Button>
      </CardActions>
    </StyledCard>
  );
};
