// Dependencies
import { AxiosResponse } from 'axios';

// Services
import { HttpService } from './http';

// Types
import { ContactsPayload, CreateContactRequest, GetContactsParams } from '../types/contacts';

export class ContactsService extends HttpService {
  private path: string;

  constructor() {
    super();
    this.path = '/contacts';
  }

  get = (params?: GetContactsParams): Promise<AxiosResponse<ContactsPayload[]>> =>
    this.request({ method: 'get', url: this.path, params });

  create = (data: CreateContactRequest): Promise<AxiosResponse<any>> =>
    this.request({
      method: 'post',
      url: `${this.path}/create`,
      data,
    });
}
