// Utils
import { defaultLanguage } from '../i18n';
import { UserState } from '../redux/features/userStateSlice';
import { UserLocation } from '../types/user';

function hasJsonStructure(str: any) {
  if (typeof str !== 'string') return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    if (type === '[object Object]' || type === '[object Array]') {
      return true;
    }
  } catch (err) {
    return false;
  }
}

const defaultKey: string = '@roastercup/';

export const getKey = (key: string) => `${defaultKey}.${key}`;

export const currentLanguageKey = 'current.language';

export const getItem = (key: string) => {
  const data = localStorage.getItem(getKey(key));
  if (data && hasJsonStructure(data)) {
    return JSON.parse(data);
  }
  return data;
};

export const setItem = (key: string, data: any) => {
  let newData = data;
  if (typeof data === 'object') {
    newData = JSON.stringify(data);
  }
  localStorage.setItem(getKey(key), newData);
};

export const showSideMenuKey = 'show.side.menu';

export const getSideMenuState = (): boolean => {
  const data = localStorage.getItem(getKey(showSideMenuKey));
  if (data === 'false') {
    return false;
  }
  return true;
};
// localStorage.clear()

export const getCurrentLanguage = (): string => {
  const data = getItem(currentLanguageKey);
  if (!data) return defaultLanguage;
  return data;
};

export const userKey = 'user';
export const ownerUserKey = 'owner.user';

export const getStoredUser = (): UserState => getItem(userKey);

export const getOwnerUser = (): UserLocation | null => getItem(ownerUserKey);
