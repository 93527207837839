// Dependencies
import axios, { AxiosRequestConfig } from 'axios';

// Utils
import { redirect, setOwnerId } from '../utils/http-utils';
export class HttpService {
  private baseURL: string;
  private baseHeaders: object;

  constructor() {
    if (window.location.protocol === 'http:') {
      this.baseURL = '/api/v1';
    } else {
      this.baseURL = 'https://roastercup-connect.com/api/v1';
    }
    this.baseHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    axios.defaults.baseURL = this.baseURL;
    axios.defaults.headers.common = this.baseHeaders;
  }

  request = async (config: AxiosRequestConfig) => {
    try {
      return await axios(setOwnerId(config));
    } catch (error) {
      throw redirect(error);
    }
  };
}
