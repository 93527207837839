// import '@mui/x-data-grid/styles.css';
// import '@mui/x-data-grid/css/style.css';
import { Box } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLoggedUser } from '../../redux/features/userStateSlice';
import { ContactsService } from '../../services/contacts';

const contactService = new ContactsService();

const Contacts = () => {
  const { t } = useTranslation();
  const user = getLoggedUser(useSelector);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['todos'],
    queryFn: () => contactService.get({ userId: user.userId }),
  });

  if (isLoading) {
    return <div>is loading</div>;
  }
  if (isError || !data) {
    return <div>no data</div>;
  }

  const contacts = data.data;

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('utilities.contacts.table.header.name'),
      flex: 1,
      renderCell(params: GridRenderCellParams) {
        return <>{params.row.name || ''}</>;
      },
    },
    {
      field: 'city',
      headerName: t('utilities.contacts.table.header.city'),
      flex: 0.5,
      renderCell(params: GridRenderCellParams) {
        return <>{params.row.city || ''}</>;
      },
    },
    {
      field: 'country',
      headerName: t('utilities.contacts.table.header.country'),
      flex: 0.5,
      renderCell(params: GridRenderCellParams) {
        return <>{params.row.country || ''}</>;
      },
    },
    {
      field: 'phone',
      headerName: t('utilities.contacts.table.header.phone'),
      flex: 0.5,
      renderCell(params: GridRenderCellParams) {
        return <>{params.row.phone || ''}</>;
      },
    },
    {
      field: 'email',
      headerName: t('utilities.contacts.table.header.email'),
      flex: 1,
      renderCell(params: GridRenderCellParams) {
        return <>{params.row.email || ''}</>;
      },
    },
  ];

  if (isLoading) {
    return <Box>{t('general.loading')}</Box>;
  }

  return (
    <Box>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={contacts}
          columns={columns}
          getRowId={(row) => row.id}
          getCellClassName={(params: GridCellParams<any, any, number>) => 'row'}
          showCellVerticalBorder
          columnThreshold={20}
          showColumnVerticalBorder
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          autoHeight
        />
      </div>
    </Box>
  );
};

export default Contacts;
