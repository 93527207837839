// Dependencies
import { List, ListItem, ListItemText, SxProps, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

// Utils
import { metafieldsList } from '../../utils/product-utils';

export type MetaFielProps = {
  title: string;
  divider: boolean;
  itemKey: string | number;
  children?: ReactNode;
  typographyProps?: TypographyProps;
};

const MetaField = ({ title, itemKey, divider, children, typographyProps, ...sxProps }: MetaFielProps & SxProps) => {
  return (
    <ListItem key={`meta-list-item-${itemKey}`} divider={divider} sx={{ paddingX: 0, ...sxProps }}>
      <ListItemText
        secondary={title}
        secondaryTypographyProps={{
          fontSize: '1.4vw',
          fontWeight: 'medium',
          ...typographyProps,
        }}
        key={`meta-item-text-${itemKey}`}
      />
      {children}
    </ListItem>
  );
};

export default MetaField;
