// Dependencies
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, colors, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import { alpha } from '@mui/material/styles';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid';
import React, { ComponentProps, Fragment } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CircularLoader from '../../components/common/CircularLoader';

// Configs
import colorConfigs from '../../configs/colorConfigs';

// Services
import moment from 'moment';
import { Columns, Datas } from 'react-csv-downloader/dist/esm/lib/csv';
import Selector, { Options } from '../../components/common/Selector';
import { getLoggedUser } from '../../redux/features/userStateSlice';
import { LabelPayload, ProductStatus } from '../../types/labels';
import { getLabelsCsvData } from '../../utils/csv-utils';
import { clearFeedbackMessage, getAllLocations, getLabels, printLabel, updateUserError } from './states/actions';
import {
  isLoadingPringSelector,
  isLoadingSelector,
  labelsSelector,
  locationsSelector,
  snackbarSelector,
} from './states/reducer';

const RequestedLabels = () => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  const labels = useSelector(labelsSelector);
  const isLoading = useSelector(isLoadingSelector);
  const snackbar = useSelector(snackbarSelector);
  const isLoadingPrint = useSelector(isLoadingPringSelector);
  const user = getLoggedUser(useSelector);
  const locations = useSelector(locationsSelector);

  const [csv, setCsv] = React.useState<Partial<{ datas: Datas; columns: Columns }>>({});
  const [locationsOpts, setLocationsOpts] = React.useState<Options[]>([]);
  const [ownerId, setOwnerId] = React.useState<string>('');
  const [filteredLabels, setFilteredLabels] = React.useState<LabelPayload[]>(labels);

  React.useEffect(() => {
    let data: Options[] = locations.map((loc) => ({
      label: loc.ownerId,
      value: loc.ownerId,
    }));
    data = [
      {
        label: `none`,
        value: ``,
      },
      ...data,
    ];
    setLocationsOpts(data);
  }, [locations]);

  const mainColums: GridColDef[] = [
    {
      field: 'date',
      headerName: t('utilities.requestedLabels.table.header.date'),
      headerClassName: 'table-header',
      renderCell(params: GridRenderCellParams) {
        const {
          row: { date },
        } = params;

        return (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Fragment>{moment(date).format('DD/MM/YYYY')}</Fragment>
          </div>
        );
      },
      flex: 0.3,
    },
    {
      field: 'name',
      headerName: t('utilities.requestedLabels.table.header.name'),
      headerClassName: 'table-header',
      renderCell(params: GridRenderCellParams) {
        const {
          row: { name },
        } = params;

        return (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Fragment>{name}</Fragment>
          </div>
        );
      },
      flex: 1,
    },
    {
      field: 'city',
      headerName: t('utilities.requestedLabels.table.header.city'),
      headerClassName: 'table-header',
      renderCell(params: GridRenderCellParams) {
        const {
          row: { city },
        } = params;

        return (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Fragment>{city}</Fragment>
          </div>
        );
      },
      flex: 0.5,
    },
    {
      field: 'country',
      headerName: t('utilities.requestedLabels.table.header.country'),
      headerClassName: 'table-header',
      renderCell(params: GridRenderCellParams) {
        const {
          row: { country },
        } = params;

        return (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Fragment>{country}</Fragment>
          </div>
        );
      },
      flex: 0.4,
    },
    {
      field: 'parcels',
      headerName: t('utilities.requestedLabels.table.header.parcels'),
      headerClassName: 'table-header',
      renderCell(params: GridRenderCellParams) {
        const {
          row: { numberOfParcels },
        } = params;

        return (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Fragment>{numberOfParcels}</Fragment>
          </div>
        );
      },
      flex: 0.3,
    },
    {
      field: 'status',
      headerName: t('utilities.requestedLabels.table.header.status'),
      headerClassName: 'table-header',
      renderCell(params: GridRenderCellParams) {
        const {
          row: { status },
        } = params;
        const colorMap: Record<ProductStatus, ComponentProps<typeof Chip>['color']> = {
          Delivered: 'success',
          Issue: 'error',
          'In transit': 'info',
        } as const;
        const color = colorMap[status as ProductStatus] || 'default';

        const label = t(`utilities.requestedLabels.status.${status || 'N/A'}`);

        return (
          <Stack direction="row" justifyContent="center" sx={{ width: '100%' }}>
            <Chip label={label} color={color} variant="filled" sx={{ fontWeight: 'bold' }} />
          </Stack>
        );
      },
      flex: 0.3,
    },
    {
      field: 'tracking',
      headerName: t('utilities.requestedLabels.table.header.tracking'),
      headerClassName: 'table-header',
      renderCell(params: GridRenderCellParams) {
        const {
          row: { trackingId },
        } = params;

        return (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Fragment>{trackingId}</Fragment>
          </div>
        );
      },
      flex: 0.5,
    },
    {
      field: 'action',
      headerName: t('utilities.requestedLabels.table.header.actions.title'),
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'table-header',
      renderCell(params: GridRenderCellParams) {
        const value = t('utilities.requestedLabels.table.header.actions.button');
        const label = params.row as LabelPayload;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <LoadingButton
              size="small"
              variant="contained"
              // onClick={() => dispatch(createLabel(label))}
              onClick={() => dispatch(printLabel({ id: label.ddpOrderId! }))}
              sx={{ fontSize: '0.8vw' }}
              loading={isLoadingPrint}
            >
              {value}
            </LoadingButton>
          </div>
        );
      },
      flex: 0.25,
    },
  ];

  let columns = mainColums;

  React.useEffect(() => {
    dispatch(getLabels());
  }, []);

  React.useEffect(() => {
    if (user.isAdmin) {
      dispatch(getAllLocations());
    }
  }, [user]);

  React.useEffect(() => {
    let data = labels;
    if (!!ownerId) {
      data = labels.filter((label) => label.ownerId === ownerId);
    }
    setFilteredLabels(data);
  }, [ownerId, labels]);

  if (user.isAdmin) {
    columns = [
      ...mainColums.slice(0, mainColums.length - 1),
      {
        field: 'locations',
        headerName: t('utilities.requestedLabels.table.header.location'),
        headerClassName: 'table-header',
        renderCell(params: GridRenderCellParams) {
          const {
            row: { ownerId },
          } = params;

          return (
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <Fragment>{ownerId}</Fragment>
            </div>
          );
        },
        flex: 0.5,
      },
      ...mainColums.slice(mainColums.length - 1, mainColums.length),
    ];
  }

  React.useEffect(() => {
    const data = getLabelsCsvData(filteredLabels, t);
    setCsv(data);
  }, [t, filteredLabels]);

  const handleCloseSnackbar = () => dispatch(clearFeedbackMessage());

  const handleProcessRowUpdateError = React.useCallback(() => {
    dispatch(
      updateUserError({
        children: t('utilities.manage.feedback.save.error'),
        severity: 'error',
      }),
    );
  }, [dispatch, t]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        flexGrow: 1,
        '& .table-header': {
          backgroundColor: colorConfigs.topbar.bg,
          fontSize: '1vw',
        },
        '& .disabled': {
          backgroundColor: alpha(colors.grey['300'], 0.4),
          fontSize: '0.9vw',
          padding: 2,
        },
        '& .row': {
          fontSize: '0.9vw',
          padding: 2,
        },
      }}
    >
      {user.isAdmin && (
        <Selector
          label={t('utilities.requestedLabels.selector.label')}
          sx={{
            marginBottom: '2vw',
            '& .MuiSelect-select': {
              backgroundColor: colorConfigs.mainBg,
            },
          }}
          options={locationsOpts}
          handleSelect={(val) => setOwnerId(val)}
        />
      )}
      <div style={{ display: 'flex', height: '50px' }}>
        <CsvDownloader
          datas={csv.datas || []}
          columns={csv.columns}
          filename={`labels-history (${moment().format('L')})`}
          separator=";"
          style={{ position: 'absolute', right: '1vw', cursor: 'pointer' }}
        >
          <LoadingButton variant="contained" disabled={false} fullWidth loading={false}>
            {t('utilities.requestedLabels.buttons.csv.title')}
          </LoadingButton>
        </CsvDownloader>
      </div>
      <DataGrid
        getRowId={(row) => {
          // console.log(row)
          return row.id;
        }}
        rows={filteredLabels}
        columns={columns}
        getCellClassName={(params: GridCellParams<any, any, number>) => {
          // if (isProductDisabled(params, rows)) {
          //     return 'disabled'
          // }
          return 'row';
        }}
        showCellVerticalBorder
        columnThreshold={20}
        showColumnVerticalBorder
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        apiRef={apiRef}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        autoPageSize
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
};

export default RequestedLabels;
