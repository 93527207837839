// Dependencies
import {
  InputBase,
  InputLabel,
  FormControl,
  styled,
  alpha,
  InputBaseProps,
  Box,
  TextField,
  TextFieldProps,
} from '@mui/material';

// Configurations
import colorConfigs from '../../configs/colorConfigs';

export type InputBaseComponentProps = InputBaseProps & {
  label?: string;
  placeholder?: string;
};

const StyledInputBase = styled(InputBase)(({ theme, style }) => ({
  'label + &': {
    //   marginTop: theme.spacing(3),
    // marginTop: style?.marginTop && '2vw' || '',
    marginTop: '2vw',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? colorConfigs.mainBg : '#1A2027',
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
    fontSize: '1.2vw',
    padding: '0.5vw',
    lineHeight: '1.6vw',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const InputBaseComponent = (props: InputBaseComponentProps) => (
  <FormControl variant="standard" fullWidth={props.fullWidth} style={{ ...props.style }}>
    <InputLabel
      shrink
      sx={{
        fontWeight: props.style?.fontWeight || 'medium',
        fontSize: '1.4vw',
      }}
    >
      {props.label}
    </InputLabel>
    <StyledInputBase {...props} />
  </FormControl>
);

export type TextFieldInputProps = {
  itemkey?: string;
};

export const TextFieldInput = (props: TextFieldProps & TextFieldInputProps) => {
  return (
    <Box sx={{ ...props.sx }} key={`box-${props.itemkey}`}>
      <FormControl fullWidth={props.fullWidth} size="small" key={`form-${props.itemkey}`}>
        <InputLabel key={`input-label-${props.itemkey}`}>{props.label}</InputLabel>
        <TextField
          size={props.size || 'small'}
          {...props}
          key={`text-field-${props.itemkey}`}
          variant={props.variant || 'outlined'}
          margin="none"
          sx={{
            fontSize: '1vw',
          }}
        />
      </FormControl>
    </Box>
  );
};
