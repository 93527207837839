export default {
  translations: {
    general: {
      apiError: 'Request error, please try again...',
    },
    searchbar: {
      loading: 'Loading',
      noResults: 'No results found',
    },
    sidebar: {
      products: {
        manage: 'Manage Products',
        add: 'Add Product',
      },
      orders: {
        manage: 'Manage Orders',
      },
      requests: {
        main: 'Requests',
        'delete-product': 'Delete Product',
        'change-price': 'Change Price',
      },
      buttons: {
        logout: {
          label: 'log out',
        },
      },
      utilities: {
        main: 'Logistics Service',
        'manage-label': 'Manage',
        'create-label': 'Create Label',
        'requested-labels': 'History',
      },
    },
    topbar: {
      selector: {
        label: 'Language',
      },
    },
    languages: {
      english: 'English',
      german: 'German',
      french: 'French',
    },
    countries: {
      luxembourg: 'Luxembourg',
      belgium: 'Belgium',
      france: 'France',
      netherlands: 'Netherlands',
    },
    orders: {
      selector: {
        label: 'Select a location',
      },
      modal: {
        button: {
          label: 'send',
        },
        title: 'Reason to reject',
        title2: 'Rejection reason',
        options: {
          0: 'Product out of stock',
          1: 'Labor shortage',
          2: 'Packaging material shortage',
        },
        title3: 'How many boxes did you use to pack this order?',
      },
      status: {
        new: 'new',
        pending: 'pending',
        packaged: 'packaged',
        completed: 'completed',
        declined: 'declined',
      },
      feedback: {
        statusUpdate: {
          success: 'Order successfully moved to {{status}}',
          error: 'Error while updating the order status',
        },
        print: {
          error: 'Error while downloading order details',
        },
        adminOrderDelete: {
          confirm: 'Are you sure you want to delete this order?',
          error: 'Error while deleting the order',
          success: 'Order successfully deleted',
        },
      },
      cards: {
        orderId: 'Order id',
        date: 'Date',
        price: 'Price',
        amount: 'Quantity',
        product: 'Product',
        weight: 'Weight',
        location: 'Location',
        orderNumber: 'Shopify Id',
        type: 'Type',
        totalAmount: 'Total Quantity',
        buttons: {
          reject: 'reject',
          accept: 'accept',
          ready: 'ready',
          complete: 'complete',
          pending: 'pending',
          packaged: 'packaged',
          moveTo: 'move to',
          print: 'print',
          delete: 'delete',
        },
      },
      configurations: {
        switches: {
          date: {
            label: 'Show date',
          },
          amount: {
            label: 'Show total quantity',
          },
          status: {
            label: 'Show declined orders',
          },
          priority: {
            label: 'Show priority orders',
          },
        },
        filters: {
          date: {
            label: 'Filter by date',
            from: 'Date from',
            to: 'Date to',
          },
        },
      },
      buttons: {
        exportCsv: 'Export to csv',
        moveAllToCompleted: 'Move all to completed',
      },
    },
    products: {
      add: {
        sections: {
          general: {
            title: 'General Information',
            inputs: {
              first: {
                label: 'Product Name',
                placeholder: '',
              },
              second: {
                label: 'Description',
              },
            },
          },
          media: {
            title: 'Media',
            buttons: {
              first: 'Upload new',
            },
            description: {
              first: 'Only upload images up to 3MB',
              second: 'Supported formats: .png or .jpg',
            },
          },
          pricing: {
            title: 'Pricing',
            inputs: {
              first: {
                label: 'In-store price',
              },
            },
          },
          metafields: {
            title: 'Product Attribute',
            fields: {
              exclusiveToRoasterCup: {
                title: 'Exclusive to Roaster Cup?',
                options: {
                  0: 'Yes exclusive',
                  1: 'Not exclusive',
                },
              },
              typeOfProductListing: {
                title: 'type of product listing',
                options: {
                  0: 'whole bean coffee',
                  1: 'ground coffee',
                  2: 'tea',
                  3: 'accessories',
                  4: 'equipment',
                },
              },
              netWeight: {
                title: 'Net Weight',
                placeholder: '000 grams',
              },
              roasterCountry: {
                title: 'roaster_country',
                options: {
                  0: 'Belgium',
                  1: 'France',
                  2: 'Germany',
                  3: 'Luxembourg',
                },
              },
              decafYesNo: {
                title: 'Decaf?',
                options: {
                  0: 'Yes',
                  1: 'No',
                },
              },
              coffeeFlavor: {
                title: 'Coffee Flavor',
                options: {
                  0: 'Fruity',
                  1: 'Floral',
                  2: 'Green / Vegetative',
                  3: 'Nutty / Cocoa',
                  4: 'Roasted',
                  5: 'Sweet',
                  6: 'Sour / Fermented',
                  7: 'Spices',
                },
              },
              roastType: {
                title: 'Roast Type',
                options: {
                  0: 'Light roast',
                  1: 'Medium roast',
                  2: 'Dark roast',
                },
              },
              singleOrigin: {
                title: 'Origin',
                options: {
                  0: 'Single origin - Brazil',
                  1: 'Single origin - Colombia',
                  2: 'Single origin - Costa Rica',
                  3: 'Single origin - Ethiopia',
                  4: 'Single origin - Guatemala',
                  5: 'Single origin - Honduras',
                  6: 'Single origin - Indonesia',
                  7: 'Single origin - Jamaica',
                  8: 'Single origin - Kenya',
                  9: 'Single origin - Peru',
                  10: 'Single origin - Rwanda',
                  11: 'Single origin - other country',
                  12: 'Blend - several origins',
                },
              },
              beanType: {
                title: 'Bean Type',
                options: {
                  0: 'Arabica',
                  1: 'Robusta',
                  2: 'Mix of Arabica and Robusta',
                  3: 'Mix with other than Arabica or Robusta',
                },
              },
              accessories: {
                title: 'Acessories',
                options: {
                  0: 'Aeropress',
                  1: 'Chemex',
                  2: 'French press',
                  3: 'Grinder',
                  4: 'Glasses',
                  5: 'Other acessories',
                },
              },
              equipment: {
                title: 'Equipment',
                options: {
                  0: 'Drip coffee maker',
                  1: 'Espresso automatic machine',
                  2: 'Other automated extraction equipment',
                },
              },
              organic: {
                title: 'Organic (bio)',
                options: {
                  0: 'True',
                  1: 'False',
                },
              },
              fairtrade: {
                title: 'Fairtrade',
                options: {
                  0: 'True',
                  1: 'False',
                },
              },
              grind: {
                title: 'Grind',
                options: {
                  0: 'Coarse (ideal for french press and chemex)',
                  1: 'Coarse to Medium (ideal for filter coffee-maker)',
                  2: 'Medium (ideal for moka))',
                  3: 'Fine (ideal for espresso)',
                },
              },
              process: {
                title: 'Process',
                options: {
                  0: 'Washed',
                  1: 'Semi-washed',
                  2: 'Natural',
                  3: 'Other',
                },
              },
              netWeightVariant: {
                title: 'Net Weight',
                placeholder: '000 grams',
              },
              priceVariant: {
                title: 'In-store price',
                placeholder: '€ 0.00',
              },
            },
            menu: {
              product: 'Product',
              variant: 'Variant',
            },
          },
        },
        buttons: {
          save: 'Save',
          showAll: 'Show all',
          hide: 'Hide',
        },
        feedback: {
          save: {
            success: 'Product added with success!',
            error: 'Error while adding product, please try again!',
          },
        },
      },
      manage: {
        modal: {
          button: {
            label: 'send',
          },
          title: 'Reason for deletion',
          options: {
            0: 'Discontinued product',
            1: 'Temporarily removed',
            2: 'Other reason (add text)',
          },
          price: {
            title: 'Price change request',
            button: 'Request',
          },
          inventory: {
            title: 'Update inventory',
            button: 'Update',
          },
        },
        feedback: {
          save: {
            success: 'Product successfully saved',
            error: 'Error while saving the product',
          },
          remove: {
            success: 'Product successfully removed',
            error: 'Error while removing product',
          },
        },
        table: {
          header: {
            product: 'Product',
            inventory: 'Inventory',
            category: 'Category',
            price: 'In-store Price',
            type: 'Variant',
            preDiscountPrice: 'Pre discount \n price',
            actions: {
              remove: 'Remove',
            },
          },
          column: {
            inventory: {
              adjunct: 'in stock',
            },
            category: {
              coffee: 'coffee',
            },
          },
        },
      },
    },
    requests: {
      noResults: 'No requests found...',
      feedback: {
        update: {
          success: 'Request updated with success',
          error: 'Error while updating the request',
        },
      },
      cards: {
        buttons: {
          approve: {
            label: 'Approve',
          },
          reject: {
            label: 'Reject',
          },
        },
        product: 'Product',
        roaster: 'Roaster',
        reason: 'Reason',
        requestedPrice: 'Requested Price',
        currentPrice: 'Current Price',
      },
    },
    utilities: {
      manageLabel: {
        table: {
          header: {
            main: 'Location',
            actions: {
              title: 'Action',
              enable: 'Enable',
              disable: 'Disable',
            },
          },
        },
        feedback: {
          save: {
            error: 'Sorry an error occurred',
            success: 'Successfully updated',
          },
        },
      },
      createLabel: {
        'select-contact': 'Select contact',
        'fill-form': 'Fill form',
        'save-contact': 'Save contact',
        button: 'Print & save',
        parcel: 'Parcel',
        inputs: {
          first: {
            label: 'Full name',
            placeholder: 'Full name',
          },
          second: {
            label: 'Country/region',
            placeholder: 'Country/region',
          },
          third: {
            label: 'Address',
            placeholder: 'Address',
          },
          fourth: {
            label: 'Additional Information (Apartment, suite, etc...)',
            placeholder: '',
          },
          fifth: {
            label: 'Postal Code',
            placeholder: 'Postal Code',
          },
          sixth: {
            label: 'City',
            placeholder: 'City',
          },
          seventh: {
            label: 'Email (optional)',
            placeholder: 'Email',
          },
          eigth: {
            label: 'Phone (optional)',
            placeholder: 'Phone with no code',
          },
          ninth: {
            label: 'Number of parcels',
            placeholder: 'Weight in kg (max 15kg)',
          },
          tenth: {
            label: 'Select a location',
          },
        },
      },
      requestedLabels: {
        selector: {
          label: 'Select a location',
        },
        buttons: {
          csv: {
            title: 'Export to csv',
          },
        },
        table: {
          header: {
            date: 'Date',
            name: 'Name',
            city: 'City',
            country: 'Country',
            parcels: '# of parcels',
            status: 'Status',
            tracking: 'Tracking Id',
            location: 'Location',
            actions: {
              title: 'Action',
              button: 'Print',
            },
          },
        },
        status: {
          Delivered: 'Delivered',
          Issue: 'Issue',
          'In transit': 'In transit',
          'N/A': 'N/A',
        },
        feedback: {
          save: {
            error: 'Sorry an error occured',
            success: 'Successfully printed',
          },
        },
      },
    },
  },
};
