// Dependencies
import { ofType, combineEpics } from 'redux-observable';
import { catchError, map, mergeMap, finalize, delay } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
// Utils
import { GetAllRequestsAction, RequestsActions, RequestsActionsTypes, UpdateAction } from './types';
import { RequestsService } from '../../../services/requests';
import { mapRequestModel } from '../../../utils/requests-utils';
import {
  clearFeedbackMessage,
  getAllRequestsError,
  getAllRequestsSuccess,
  updateError,
  updateSuccess,
} from './actions';
import { translate } from '../../../i18n';

const requestsService = new RequestsService();

const getAllRequestsEpic = (actions$: Observable<RequestsActions>): any =>
  actions$.pipe(
    ofType<RequestsActions, RequestsActionsTypes.GET_ALL_REQUESTS, GetAllRequestsAction>(
      RequestsActionsTypes.GET_ALL_REQUESTS,
    ),
    mergeMap(({ payload: { type } }) =>
      from(requestsService.get({ type })).pipe(
        map(({ data }) => {
          const requests = data.filter((data) => !!data.object).map(mapRequestModel);
          return getAllRequestsSuccess(requests);
        }),
        catchError((error) => {
          console.log(error);
          return of(
            getAllRequestsError({
              children: translate('general.apiError'),
              severity: 'error',
            }),
          );
        }),
        finalize(() => clearFeedbackMessage()),
      ),
    ),
  );

const updateRequestEpic = (actions$: Observable<RequestsActions>): any =>
  actions$.pipe(
    ofType<RequestsActions, RequestsActionsTypes.UPDATE, UpdateAction>(RequestsActionsTypes.UPDATE),
    mergeMap(({ payload: { id, action } }) =>
      from(requestsService.update({ action }, id)).pipe(
        map(({ data }) => {
          return updateSuccess({
            data,
            snackbar: {
              children: translate('requests.feedback.update.success'),
              severity: 'success',
            },
          });
        }),
        catchError((error) => {
          console.log(error);
          return of(
            updateError({
              children: translate('requests.feedback.update.error'),
              severity: 'error',
            }),
          );
        }),
        finalize(() => clearFeedbackMessage()),
      ),
    ),
  );

export const requestsEpic: any = combineEpics(getAllRequestsEpic, updateRequestEpic);
