// Dependencies
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { useSelector } from 'react-redux';

// Utils
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import { selectShowSideMenu } from '../../redux/features/appStateSlice';

type StyledContainerProps = {
  containerWidth: string | number;
};

type ContainerProps = {
  label: string;
  onClick: () => any;
};

const Button = styled('div')<StyledContainerProps>(({ theme, containerWidth }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: 10,
  right: 0,
  left: containerWidth,
  borderRadius: 10,
  // backgroundColor: colorConfigs.sidebar.bg,
  textTransform: 'uppercase',
  backgroundColor: alpha(colorConfigs.sidebar.bg, 0.9),
  '&:hover': {
    backgroundColor: alpha(colorConfigs.sidebar.bg, 1),
  },
  padding: 5,
  color: theme.palette.common.white,
  marginLeft: sizeConfigs.mainScreen.paddingRaw,
  marginRight: sizeConfigs.mainScreen.paddingRaw,
  zIndex: 100,
}));

export default ({ label, onClick }: ContainerProps) => {
  const showSideMenu = useSelector(selectShowSideMenu);

  const [width, setWidth] = React.useState<number | string>(sizeConfigs.mainScreen.widthRaw);

  React.useEffect(() => {
    let newWidth: number | string = showSideMenu ? sizeConfigs.sidebar.width : sizeConfigs.mainScreen.paddingRaw;
    setWidth(newWidth);
  }, [showSideMenu]);

  return (
    <Button containerWidth={width} onClick={onClick}>
      {label}
    </Button>
  );
};
