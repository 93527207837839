// Dependencies
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Utils
import { RouteStates } from '../../routes/config';
import { setItem, getSideMenuState, showSideMenuKey } from '../../utils/storage-utils';

type appState = {
  currentPath: RouteStates;
  showSideMenu: boolean;
};

const initialState: appState = {
  currentPath: '',
  showSideMenu: getSideMenuState(),
};

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setCurrentPath: (state: appState, action: PayloadAction<RouteStates>) => {
      state.currentPath = action.payload;
    },
    setShowSideMenu: (state: appState) => {
      setItem(showSideMenuKey, !state.showSideMenu);
      state.showSideMenu = !state.showSideMenu;
    },
  },
  selectors: {
    selectAppState: (state: appState): appState => state,
    selectCurrentPath: (state: appState): RouteStates => state.currentPath,
    selectShowSideMenu: (state: appState): boolean => state.showSideMenu,
  },
});

export const { setCurrentPath, setShowSideMenu } = appStateSlice.actions;

export const { selectCurrentPath, selectShowSideMenu, selectAppState } = appStateSlice.selectors;

export default appStateSlice.reducer;
