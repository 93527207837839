// Utils
import {
  Actions,
  GetPendingRequestsParams,
  PendingRequestModel,
  RequestId,
  PendingRequests,
} from '../../../types/requests';
import { RequestsActionsTypes } from './types';

export function getAllRequests(payload: GetPendingRequestsParams) {
  return {
    type: RequestsActionsTypes.GET_ALL_REQUESTS,
    payload,
  };
}

export function getAllRequestsSuccess(payload: PendingRequestModel[]) {
  return {
    type: RequestsActionsTypes.GET_ALL_REQUESTS_SUCCESS,
    payload,
  };
}

export function getAllRequestsError(payload: any) {
  return {
    type: RequestsActionsTypes.GET_ALL_REQUESTS_ERROR,
    payload,
  };
}

export function update(payload: { id: RequestId; action: Actions }) {
  return {
    type: RequestsActionsTypes.UPDATE,
    payload,
  };
}

export function updateSuccess(payload: { data: PendingRequests; snackbar: any }) {
  return {
    type: RequestsActionsTypes.UPDATE_SUCCESS,
    payload,
  };
}

export function updateError(payload: any) {
  return {
    type: RequestsActionsTypes.UPDATE_ERROR,
    payload,
  };
}

export function clearFeedbackMessage() {
  return {
    type: RequestsActionsTypes.CLEAR_FEEDBACK_MESSAGE,
  };
}
