// Dependencies
import { AxiosResponse } from 'axios';

// Services
import { HttpService } from './http';

// Types
import { Product, ProductModel, DeleteProductData } from '../types/products';
import { PendingRequests } from '../types/requests';

export class ProductsService extends HttpService {
  private path: string;

  constructor() {
    super();
    this.path = '/products';
  }

  getAll = (): Promise<AxiosResponse<Product[]>> => this.request({ method: 'get', url: this.path });

  getProduct = (id: number): Promise<AxiosResponse<Product>> =>
    this.request({ url: `${this.path}/${id}`, method: 'get' });

  add = (data: Partial<Product>): Promise<AxiosResponse<Product>> =>
    this.request({ data, method: 'post', url: this.path });

  update = (data: Partial<ProductModel>, id: number): Promise<AxiosResponse<Product>> =>
    this.request({ data, url: `${this.path}/${id}`, method: 'put' });

  deleteProduct = (data: DeleteProductData, id: number): Promise<AxiosResponse<PendingRequests>> =>
    this.request({ url: `${this.path}/${id}`, method: 'delete', data });
}
