// Dependencies
import { colors, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Utils
import colorConfigs from '../../configs/colorConfigs';
import { SelectFieldTypes } from '../../types/products';

export type TableCellProps = {
  onClick: () => any;
  field: SelectFieldTypes;
  values: Array<number | string>;
  texts: Array<string>;
  disabled: boolean;
};

const Value = styled(Typography)(() => ({
  fontSize: '0.9vw',
  marginRight: '0.5vw',
}));

export default ({ onClick, field, values, texts, disabled }: TableCellProps) => {
  const getTextColor = (index: number) => {
    if (!index && ((field === 'inventory' && values[0] <= 5) || (field === 'price' && values.length > 1))) {
      return colorConfigs.warningMsg;
    }
    return colors.grey['800'];
  };

  return (
    <div
      style={{
        flex: 1,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={() => !disabled && onClick()}
    >
      {texts.map((text, index) => (
        <Value key={index} sx={{ color: getTextColor(index) }}>
          {`${values[index]} ${text}`}
        </Value>
      ))}
    </div>
  );
};
