// Dependencies
import React from 'react';
import { AppBar, colors, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import { currentLanguageKey, setItem, getCurrentLanguage } from '../../utils/storage-utils';

// Components
import Selector, { Options } from '../common/Selector';
import { selectAppState, setShowSideMenu } from '../../redux/features/appStateSlice';

// Services
import { UsersService } from '../../services/users';
import { getLoggedUser } from '../../redux/features/userStateSlice';

export const TOPBAR_HEIGHT = 64;

const usersService = new UsersService();

const Topbar = () => {
  const { t, i18n } = useTranslation();
  const appState = useSelector(selectAppState);
  const dispatch = useDispatch();
  const user = getLoggedUser(useSelector);

  const { showSideMenu, currentPath } = appState;

  const [iconColor] = React.useState(colors.grey['800']);

  React.useEffect(() => {
    const lang = getCurrentLanguage();
    i18n.changeLanguage(lang);
  }, [user?.language]);

  const options: Options[] = [
    {
      label: t('languages.english'),
      value: 'en-US',
    },
    // {
    //   label:  t('languages.german'),
    //   value: 'de'
    // },
    {
      label: t('languages.french'),
      value: 'fr',
    },
  ];

  const onSelect = React.useCallback(
    async (value: string) => {
      await usersService.setLanguage({ language: value });
      i18n.changeLanguage(value);
    },
    [i18n.language],
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${showSideMenu ? sizeConfigs.sidebar.width : 0})`,
        // ml: sizeConfigs.sidebar.width,
        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
        alignItems: 'center',
      }}
    >
      <Toolbar sx={{ width: '100%', justifyContent: 'space-between' }}>
        <div onClick={() => dispatch(setShowSideMenu())} style={{ alignItems: 'center', display: 'flex' }}>
          {showSideMenu ? <ChevronLeftIcon style={{ color: iconColor }} /> : <MenuItem style={{ color: iconColor }} />}
          <Typography
            sx={{
              fontSize: '1.8vw',
              fontWeight: 'medium',
              marginLeft: '1.5vw',
              color: colors.grey['800'],
            }}
          >
            {t(`sidebar.${currentPath}`)}
          </Typography>
        </div>
        <Selector
          options={options}
          handleSelect={onSelect}
          initialValue={getCurrentLanguage()}
          sx={{
            minWidth: '13vw',
          }}
        />
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
