// Dependencies
import React from 'react';
import { List, ListItem, Box, Snackbar, Alert, Typography, colors } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { useTranslation } from 'react-i18next';

// Components
import CircularLoader from '../../components/common/CircularLoader';
import { RequestsCard } from '../../components/common/RequestsCard';

// Utils
import { selectCurrentPath } from '../../redux/features/appStateSlice';
import { isLoadingSelector, requestsSelector, snackbarSelector } from './states/reducer';
import { clearFeedbackMessage, getAllRequests } from './states/actions';
import { PendingRequestsTypes } from '../../types/requests';

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentPath = useSelector(selectCurrentPath);
  const requests = useSelector(requestsSelector);
  const isLoading = useSelector(isLoadingSelector);
  const snackbar = useSelector(snackbarSelector);

  const handleCloseSnackbar = () => dispatch(clearFeedbackMessage());

  React.useEffect(() => {
    let type: PendingRequestsTypes | null = null;
    if (currentPath === 'requests.delete-product') {
      type = 'PRODUCT_DELETION';
    } else if (currentPath === 'requests.change-price') {
      type = 'PRODUCT_PRICE_CHANGE';
    }
    !!type && dispatch(getAllRequests({ type }));
  }, [currentPath]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <List id={`requests-list-${currentPath}`} sx={{ '& .MuiListItem-root': { p: 0, m: 0 } }}>
        {!requests.length && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: 5,
            }}
          >
            <Box
              sx={{
                width: 200,
                height: 200,
                borderRadius: 100,
                backgroundColor: colors.grey[300],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NewspaperIcon sx={{ color: colors.grey[600], fontSize: 70 }} />
            </Box>
            <Typography
              variant="h6"
              sx={{
                color: colors.grey[700],
                marginTop: 2,
                fontWeight: 'medium',
              }}
            >
              {t('requests.noResults')}
            </Typography>
          </Box>
        )}
        {requests.map((request) => (
          <ListItem key={request.id}>
            <RequestsCard item={request} />
          </ListItem>
        ))}
      </List>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
};
