// Dependencies
import { AxiosRequestConfig } from 'axios';

// Utils
import { getOwnerUser } from './storage-utils';

export const redirect = (error: any) => {
  if (error?.request?.status === 401 || error?.request?.status === 403) {
    let uri = 'https%3A%2F%2Froastercup-connect.com%2Fapi%2Fauth';
    if (window.location.host.includes('localhost')) {
      uri = 'http%3A%2F%2Flocalhost%3A8000%2Fapi%2Fauth';
    }
    window.location.href =
      'https://auth.roastercup-connect.com/login?client_id=4n5o5j08tp23544i4e4l58tq3d&response_type=code&scope=email+openid+aws.cognito.signin.user.admin&redirect_uri=' +
      uri;
  }
  return error;
};

export const setOwnerId = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const ownerUser = getOwnerUser();
  const ownerId = ownerUser?.ownerId;
  if (ownerId) {
    const method = config.method?.toLowerCase();
    const path = config.url;
    if ((path?.includes('orders') || path?.includes('products')) && method === 'get') {
      config.params = { ...config.params, ownerId };
    } else if (path?.includes('products') && method === 'post') {
      config.data = { ...config.data, ownerId };
    }
  }
  return config;
};
