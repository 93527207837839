// Dependencies
import { DateValidationError } from '@mui/x-date-pickers';
import { Moment } from 'moment';

// Utils
import { FilterOrdersSwitchesState, OrderModel, OrderStatusTypes } from '../../../types/orders';

export enum ManageOrdersActionsTypes {
  UPDATE_STATUS = '@manageOrders/UPDATE_STATUS',
  UPDATE_STATUS_SUCCESS = '@manageOrders/UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_ERROR = '@manageOrders/UPDATE_STATUS_ERROR',
  GET_ALL = '@manageOrders/GET_ALL',
  GET_ALL_SUCCESS = '@manageOrders/GET_ALL_SUCCESS',
  GET_ALL_ERROR = '@manageOrders/GET_ALL_ERROR',
  SELECT_ORDER = '@manageOrders/SELECT_ORDER',
  ADMIN_DELETE_ORDER = '@manageOrders/ADMIN_DELETE_ORDER',
  ADMIN_DELETE_ORDER_SUCCESS = '@manageOrders/ADMIN_DELETE_ORDER_SUCCESS',
  ADMIN_DELETE_ORDER_ERROR = '@manageOrders/ADMIN_DELETE_ORDER_ERROR',
  FILTER_ORDERS = '@manageOrders/FILTER_ORDERS',
  CLEAR_FEEDBACK_MESSAGE = '@manageOrders/CLEAR_FEEDBACK_MESSAGE',
  ORDER_DECLINEMENT_REASON = '@manageOrders/ORDER_DECLINEMENT_REASON',
  SELECT_MULTIPLE_ORDERS = '@manageOrders/SELECT_MULTIPLE_ORDERS',
  HANDLE_SWITCHES = '@manageOrders/HANDLE_SWITCHES',
  UPDATE_MULTIPLE_STATUS = '@manageOrders/UPDATE_MULTIPLE_STATUS',
  UPDATE_MULTIPLE_STATUS_SUCCESS = '@manageOrders/UPDATE_MULTIPLE_STATUS_SUCCESS',
  UPDATE_MULTIPLE_STATUS_ERROR = '@manageOrders/UPDATE_MULTIPLE_STATUS_ERROR',
  PRINT = '@manageOrders/PRINT',
  PRINT_SUCCESS = '@manageOrders/PRINT_SUCCESS',
  PRINT_ERROR = '@manageOrders/PRINT_ERROR',
}

export interface UpdateStatusAction {
  type: typeof ManageOrdersActionsTypes.UPDATE_STATUS;
  payload: any;
}

export interface UpdateStatusSuccessAction {
  type: typeof ManageOrdersActionsTypes.UPDATE_STATUS_SUCCESS;
  payload: {
    orders: OrderModel[];
    snackbar: any;
  };
}

export interface UpdateStatusErrorAction {
  type: typeof ManageOrdersActionsTypes.UPDATE_STATUS_ERROR;
  payload: any;
}

export interface GetAllAction {
  type: typeof ManageOrdersActionsTypes.GET_ALL;
  payload: {
    useLoading: boolean;
    dateFrom: Moment | null;
    dateTo: Moment | null;
  };
}

export interface GetAllSuccessAction {
  type: typeof ManageOrdersActionsTypes.GET_ALL_SUCCESS;
  payload: OrderModel[];
}

export interface GetAllErrorAction {
  type: typeof ManageOrdersActionsTypes.GET_ALL_ERROR;
  payload: any;
}
export interface SelectOrderAction {
  type: typeof ManageOrdersActionsTypes.SELECT_ORDER;
  payload: OrderModel | any;
}

export interface AdminDeleteOrderAction {
  type: typeof ManageOrdersActionsTypes.ADMIN_DELETE_ORDER;
  payload: any;
}

export interface AdminDeleteOrderSuccessAction {
  type: typeof ManageOrdersActionsTypes.ADMIN_DELETE_ORDER_SUCCESS;
  payload: {
    orders: OrderModel[];
    snackbar: any;
  };
}

export interface AdminDeleteOrderErrorAction {
  type: typeof ManageOrdersActionsTypes.ADMIN_DELETE_ORDER_ERROR;
  payload: any;
}

export interface FilterOrdersAction {
  type: typeof ManageOrdersActionsTypes.FILTER_ORDERS;
  payload: {
    dateFrom: null | Moment;
    dateTo: null | Moment;
    dateError: DateValidationError | undefined;
    orders: OrderModel[];
    ownerId: string;
  };
}

export interface ClearFeedbackMessageAction {
  type: typeof ManageOrdersActionsTypes.CLEAR_FEEDBACK_MESSAGE;
  payload: any;
}
export interface OrderDeclinementReasonAction {
  type: typeof ManageOrdersActionsTypes.ORDER_DECLINEMENT_REASON;
  payload: any;
}

export interface SelectMultipleOrdersAction {
  type: typeof ManageOrdersActionsTypes.SELECT_MULTIPLE_ORDERS;
  payload: OrderModel | null;
}

export interface HandleSwitchesAction {
  type: typeof ManageOrdersActionsTypes.HANDLE_SWITCHES;
  payload: FilterOrdersSwitchesState | null;
}

export interface UpdateMultipleStatusAction {
  type: typeof ManageOrdersActionsTypes.UPDATE_MULTIPLE_STATUS;
  payload: {
    status: OrderStatusTypes;
  };
}

export interface UpdateMultipleStatusSuccessAction {
  type: typeof ManageOrdersActionsTypes.UPDATE_MULTIPLE_STATUS_SUCCESS;
  payload: {
    orders: OrderModel[];
    snackbar: any;
  };
}

export interface UpdateMultipleStatusErrorAction {
  type: typeof ManageOrdersActionsTypes.UPDATE_MULTIPLE_STATUS_ERROR;
  payload: any;
}

export interface PrintAction {
  type: typeof ManageOrdersActionsTypes.PRINT;
  payload: {
    orderId: number;
    params: { ownerId?: string; parcelsCount: number } | null;
  };
}

export interface PrintSuccessAction {
  type: typeof ManageOrdersActionsTypes.PRINT_SUCCESS;
}

export interface PrintErrorAction {
  type: typeof ManageOrdersActionsTypes.PRINT_ERROR;
  payload: any;
}

export type ManageOrdersActions =
  | UpdateStatusAction
  | UpdateStatusSuccessAction
  | UpdateStatusErrorAction
  | GetAllAction
  | GetAllSuccessAction
  | GetAllErrorAction
  | SelectOrderAction
  | AdminDeleteOrderAction
  | AdminDeleteOrderSuccessAction
  | AdminDeleteOrderErrorAction
  | FilterOrdersAction
  | ClearFeedbackMessageAction
  | OrderDeclinementReasonAction
  | SelectMultipleOrdersAction
  | HandleSwitchesAction
  | UpdateMultipleStatusAction
  | UpdateMultipleStatusSuccessAction
  | UpdateMultipleStatusErrorAction
  | PrintAction
  | PrintSuccessAction
  | PrintErrorAction;
