// Dependencies
import React from 'react';
import { Button, colors, List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Selector, { MultipleSelector } from './Selector';
import { TextFieldInput } from './Inputs';

// Utils
import { getMetafields, metafieldsList, validadeNumericMetafield } from '../../utils/product-utils';
import colorConfigs from '../../configs/colorConfigs';
import {
  MetafieldComponentTypes,
  MetafieldOptions,
  MetafieldsOptionsTypes,
  MetafieldsTitles,
} from '../../types/products';
import MetaField from './Metafield';
import {
  metafieldsSelector,
  showProductsMetafieldsStateSelector,
  showVariantsMetafieldsStateSelector,
} from '../../pages/products/add/states/reducer';
import { setFields } from '../../pages/products/add/states/actions';
import { currencyMask } from '../../utils/string-utils';

export type MetaFieldsListProps = {
  disabled: boolean;
  list: Array<MetafieldOptions>;
  getButtonLabel: (item: MetafieldOptions) => MetafieldsButtons;
  handleButtonClick: (item: MetafieldOptions) => any;
};

export type MetafieldsButtons = {
  [K in MetafieldsOptionsTypes]: string;
};

const MetaFieldsList = ({ disabled, list, getButtonLabel, handleButtonClick }: MetaFieldsListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const metafields = useSelector(metafieldsSelector);
  const showAllProductsMetafields = useSelector(showProductsMetafieldsStateSelector);
  const showAllVariantsMetafields = useSelector(showVariantsMetafieldsStateSelector);

  React.useEffect(() => {
    const data = metafields;
    for (const param in metafields) {
      if (!list.find((field) => field.title === param)) {
        data[param as MetafieldsTitles] = '';
      }
    }
    dispatch(setFields({ metafields: data }));
  }, [list, metafields]);

  return (
    <List sx={{ widht: '100%' }} component="nav">
      {list.map((item, index) => (
        <React.Fragment key={index}>
          {item.index && (
            <MetaField
              itemKey={item.title}
              divider={item.type === 'product' || (item.type === 'variant' && showAllVariantsMetafields)}
              title={t(`products.add.sections.metafields.menu.${item.type}`)}
              typographyProps={{
                fontSize: '1.6vw',
                fontWeight: 'bold',
                color: colors.grey['800'],
              }}
            >
              <Button
                key={`button-${item.title}`}
                size="small"
                variant="text"
                onClick={() => handleButtonClick(item)}
                sx={{ fontSize: '1.2vw' }}
              >
                {getButtonLabel(item)[item.type]}
              </Button>
            </MetaField>
          )}
          {((item.type === 'product' && showAllProductsMetafields) ||
            (item.type === 'variant' && showAllVariantsMetafields)) && (
            <MetaField
              itemKey={item.title}
              divider={metafieldsList.length - 1 !== index}
              title={t(`products.add.sections.metafields.fields.${item.title}.title`)}
            >
              {item.componentType === 'select' && (
                <Selector
                  disabled={disabled}
                  key={`meta-item-${item.title}`}
                  sx={{
                    width: '40%',
                    backgroundColor: colorConfigs.mainBg,
                    '& .MuiInputBase-root': { maxHeight: '30px' },
                  }}
                  options={(item.options || []).map((opt, index) => ({
                    label: t(`products.add.sections.metafields.fields.${item.title}.options.${index}`),
                    value: index,
                  }))}
                  value={metafields[item.title]}
                  handleSelect={(val) => {
                    if (item.title === 'typeOfProductListing') {
                      dispatch(
                        setFields({
                          metafieldsList: getMetafields(parseInt(val as string)),
                        }),
                      );
                    }
                    dispatch(
                      setFields({
                        metafields: {
                          ...metafields,
                          [item.title]: val,
                        },
                      }),
                    );
                  }}
                />
              )}
              {item.componentType === 'select-multiple' && (
                <MultipleSelector
                  disabled={disabled}
                  key={`meta-item-${item.title}`}
                  sx={{
                    width: '40%',
                    backgroundColor: colorConfigs.mainBg,
                    '& .MuiInputBase-root': { maxHeight: '30px' },
                  }}
                  options={(item.options || []).map((opt, index) => ({
                    label: t(`products.add.sections.metafields.fields.${item.title}.options.${index}`),
                    value: index,
                  }))}
                  // value={[metafields[item.title]]}
                  handleSelectMultiple={(val: any) => {
                    dispatch(
                      setFields({
                        metafields: {
                          ...metafields,
                          [item.title]: val,
                        },
                      }),
                    );
                  }}
                />
              )}
              {(item.componentType === 'input' || item.componentType === 'price-input') && (
                <TextFieldInput
                  disabled={disabled}
                  itemkey={`meta-item-${item.title}`}
                  sx={{
                    width: '40%',
                    backgroundColor: colorConfigs.mainBg,
                    '& .MuiInputBase-root': {
                      maxHeight: '30px',
                      fontSize: '1.1vw',
                    },
                  }}
                  fullWidth
                  placeholder={t(`products.add.sections.metafields.fields.${item.title}.placeholder`)}
                  value={metafields[item.title]}
                  onChange={(e) => {
                    const value = item.componentType === 'input' ? e.target.value : currencyMask(e.target.value);

                    dispatch(
                      setFields({
                        metafields: {
                          ...metafields,
                          [item.title]: value,
                        },
                      }),
                    );
                  }}
                  {...(item.componentType === 'input' && { type: 'number' })}
                  // type="number"
                  error={!!metafields[item.title] && validadeNumericMetafield(item, metafields[item.title].toString())}
                />
              )}
            </MetaField>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default MetaFieldsList;
