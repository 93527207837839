// Types
import { UtilitiesActionsTypes } from './types';

import { ContactsPayload } from '../../../types/contacts';
import { LabelPayload, PrintLabel } from '../../../types/labels';
import { User, UserLocation } from '../../../types/user';

export function updateUser(payload: { user: User; userId?: string }) {
  return {
    type: UtilitiesActionsTypes.UPDATE_USER,
    payload,
  };
}

export function updateUserSuccess(payload: User[]) {
  return {
    type: UtilitiesActionsTypes.UPDATE_USER_SUCCESS,
    payload,
  };
}

export function updateUserError(payload: any) {
  return {
    type: UtilitiesActionsTypes.UPDATE_USER_ERROR,
    payload,
  };
}

export function getAllLocations(payload?: User) {
  return {
    type: UtilitiesActionsTypes.GET_ALL_LOCATIONS,
    payload,
  };
}

export function getAllLocationsSuccess(payload: UserLocation[]) {
  return {
    type: UtilitiesActionsTypes.GET_ALL_LOCATIONS_SUCCESS,
    payload,
  };
}

export function getAllLocationsError(payload: any) {
  return {
    type: UtilitiesActionsTypes.GET_ALL_LOCATIONS_ERROR,
    payload,
  };
}

export function updateByLocation(payload: { location: UserLocation }) {
  return {
    type: UtilitiesActionsTypes.UPDATE_BY_LOCATION,
    payload,
  };
}

export function updateByLocationSuccess(payload: UserLocation[]) {
  return {
    type: UtilitiesActionsTypes.UPDATE_BY_LOCATION_SUCCESS,
    payload,
  };
}

export function updateByLocationError(payload: any) {
  return {
    type: UtilitiesActionsTypes.UPDATE_BY_LOCATION_ERROR,
    payload,
  };
}

export function getLabels(payload?: { ownerId?: string }) {
  return {
    type: UtilitiesActionsTypes.GET_LABELS,
    payload,
  };
}

export function getLabelsSuccess(payload: LabelPayload[]) {
  return {
    type: UtilitiesActionsTypes.GET_LABELS_SUCCESS,
    payload,
  };
}

export function getLabelsError(payload: any) {
  return {
    type: UtilitiesActionsTypes.GET_LABELS_ERROR,
    payload,
  };
}

export function createLabel(payload: LabelPayload) {
  return {
    type: UtilitiesActionsTypes.CREATE_LABEL,
    payload,
  };
}

export function createLabelSuccess(payload: any) {
  return {
    type: UtilitiesActionsTypes.CREATE_LABEL_SUCCESS,
    payload,
  };
}

export function createLabelError(payload: any) {
  return {
    type: UtilitiesActionsTypes.CREATE_LABEL_ERROR,
    payload,
  };
}

export function printLabel(payload: PrintLabel) {
  return {
    type: UtilitiesActionsTypes.PRINT_LABEL,
    payload,
  };
}

export function printLabelSuccess(payload: any) {
  return {
    type: UtilitiesActionsTypes.PRINT_LABEL_SUCCESS,
    payload,
  };
}

export function printLabelError(payload: any) {
  return {
    type: UtilitiesActionsTypes.PRINT_LABEL_ERROR,
    payload,
  };
}

export function clearFeedbackMessage() {
  return {
    type: UtilitiesActionsTypes.CLEAR_FEEDBACK_MESSAGE,
  };
}

export function saveContact(payload: {
  userId: string;
  name: string;
  address: string;
  adjunct?: string;
  city: string;
  country: string;
  postalCode: string;
  phone: string;
  email: string;
}) {
  return {
    type: UtilitiesActionsTypes.SAVE_CONTACT,
    payload,
  };
}

export function saveContactSuccess(payload: any) {
  return {
    type: UtilitiesActionsTypes.SAVE_CONTACT_SUCCESS,
    payload,
  };
}

export function saveContactError(payload: any) {
  return {
    type: UtilitiesActionsTypes.SAVE_CONTACT_ERROR,
    payload,
  };
}

export function getContacts(payload?: { userId?: string }) {
  return {
    type: UtilitiesActionsTypes.GET_CONTACTS,
    payload,
  };
}

export function getContactsSuccess(payload: ContactsPayload[]) {
  return {
    type: UtilitiesActionsTypes.GET_CONTACTS_SUCCESS,
    payload,
  };
}

export function getContactsError(payload: any) {
  return {
    type: UtilitiesActionsTypes.GET_CONTACTS_ERROR,
    payload,
  };
}
