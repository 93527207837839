// Utils
import { RootState } from '../../../redux/store';
import { ContactsPayload } from '../../../types/contacts';
import { LabelPayload } from '../../../types/labels';
import { User, UserLocation } from '../../../types/user';
import { UtilitiesActions, UtilitiesActionsTypes } from './types';

export type UtilitiesState = {
  users: User[];
  isLoading: boolean;
  snackbar: any;
  locations: UserLocation[];
  labels: LabelPayload[];
  contacts: ContactsPayload[];
  isLoadingPrint: boolean;
  isUpdatingLocation: boolean;
  createdLabelId: number | null;
};

export const utilitiesInitialState: UtilitiesState = {
  users: [],
  isLoading: false,
  snackbar: null,
  locations: [],
  contacts: [],
  labels: [],
  isLoadingPrint: false,
  isUpdatingLocation: false,
  createdLabelId: null,
};

export const locationsSelector = (state: RootState): UserLocation[] => state.utilities.locations;

export const usersSelector = (state: RootState): User[] => state.utilities.users;

export const isLoadingSelector = (state: RootState): boolean => state.utilities.isLoading;

export const snackbarSelector = (state: RootState): any => state.utilities.snackbar;

export const labelsSelector = (state: RootState): LabelPayload[] => state.utilities.labels;

export const contactsSelector = (state: RootState): ContactsPayload[] => state.utilities.contacts;

export const isLoadingPringSelector = (state: RootState): boolean => state.utilities.isLoadingPrint;

export const isUpdatingLocationSelector = (state: RootState): boolean => state.utilities.isUpdatingLocation;

export const createdLabelIdSelector = (state: RootState): number | null => state.utilities.createdLabelId;

export const utilitiesReducer = (
  state: UtilitiesState = utilitiesInitialState,
  action: UtilitiesActions,
): UtilitiesState => {
  switch (action.type) {
    case UtilitiesActionsTypes.GET_ALL_LOCATIONS:
      return {
        ...state,
        isLoading: !utilitiesInitialState.isLoading,
      };
    case UtilitiesActionsTypes.GET_ALL_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload,
        isLoading: utilitiesInitialState.isLoading,
      };
    case UtilitiesActionsTypes.GET_ALL_LOCATIONS_ERROR:
      return {
        ...state,
        snackbar: action.payload,
        users: utilitiesInitialState.users,
        isLoading: utilitiesInitialState.isLoading,
      };
    case UtilitiesActionsTypes.UPDATE_USER:
      return {
        ...state,
        isLoading: !utilitiesInitialState.isLoading,
      };
    case UtilitiesActionsTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        isLoading: utilitiesInitialState.isLoading,
        // snackbar: action.payload.snackbar,
        users: action.payload,
      };
    }
    case UtilitiesActionsTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        isLoading: utilitiesInitialState.isLoading,
        snackbar: action.payload,
      };
    case UtilitiesActionsTypes.UPDATE_BY_LOCATION:
      return {
        ...state,
        isUpdatingLocation: !utilitiesInitialState.isUpdatingLocation,
      };
    case UtilitiesActionsTypes.UPDATE_BY_LOCATION_SUCCESS: {
      return {
        ...state,
        isUpdatingLocation: utilitiesInitialState.isUpdatingLocation,
        // snackbar: action.payload.snackbar,
        locations: action.payload,
      };
    }
    case UtilitiesActionsTypes.UPDATE_BY_LOCATION_ERROR:
      return {
        ...state,
        isUpdatingLocation: utilitiesInitialState.isUpdatingLocation,
        snackbar: action.payload,
      };
    case UtilitiesActionsTypes.GET_LABELS:
      return {
        ...state,
        isLoading: !utilitiesInitialState.isLoading,
      };
    case UtilitiesActionsTypes.GET_LABELS_SUCCESS:
      return {
        ...state,
        isLoading: utilitiesInitialState.isLoading,
        labels: action.payload,
      };
    case UtilitiesActionsTypes.GET_LABELS_ERROR:
      return {
        ...state,
        isLoading: utilitiesInitialState.isLoading,
        snackbar: action.payload,
      };
    case UtilitiesActionsTypes.CREATE_LABEL:
      return {
        ...state,
        isLoadingPrint: !utilitiesInitialState.isLoadingPrint,
      };
    case UtilitiesActionsTypes.CREATE_LABEL_SUCCESS:
      return {
        ...state,
        createdLabelId: action.payload.id!,
        isLoadingPrint: utilitiesInitialState.isLoadingPrint,
        snackbar: action.payload.snackbar,
      };
    case UtilitiesActionsTypes.CREATE_LABEL_ERROR:
      return {
        ...state,
        isLoadingPrint: utilitiesInitialState.isLoadingPrint,
        snackbar: action.payload,
      };
    case UtilitiesActionsTypes.PRINT_LABEL:
      return {
        ...state,
        isLoadingPrint: !utilitiesInitialState.isLoadingPrint,
      };
    case UtilitiesActionsTypes.PRINT_LABEL_ERROR:
    case UtilitiesActionsTypes.PRINT_LABEL_SUCCESS: {
      return {
        ...state,
        createdLabelId: null,
        isLoadingPrint: utilitiesInitialState.isLoadingPrint,
        snackbar: action.payload,
      };
    }
    case UtilitiesActionsTypes.SAVE_CONTACT:
      return {
        ...state,
        isLoading: !utilitiesInitialState.isLoading,
      };
    case UtilitiesActionsTypes.SAVE_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: utilitiesInitialState.isLoading,
        snackbar: action.payload,
      };
    case UtilitiesActionsTypes.SAVE_CONTACT_ERROR:
      return {
        ...state,
        isLoading: utilitiesInitialState.isLoading,
        snackbar: action.payload,
      };
    case UtilitiesActionsTypes.CLEAR_FEEDBACK_MESSAGE:
      return {
        ...state,
        snackbar: utilitiesInitialState.snackbar,
      };
    case UtilitiesActionsTypes.GET_CONTACTS:
      return {
        ...state,
        isLoading: !utilitiesInitialState.isLoading,
      };
    case UtilitiesActionsTypes.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        isLoading: utilitiesInitialState.isLoading,
        // Use any needed state property to store contacts
        snackbar: null,
      };
    case UtilitiesActionsTypes.GET_CONTACTS_ERROR:
      return {
        ...state,
        isLoading: utilitiesInitialState.isLoading,
        snackbar: action.payload,
      };
    default:
      return state;
  }
};
